import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    circle: false,
}

const circleSlice = createSlice({
    name: 'circle',
    initialState,
    reducers: {
        addCircle: (state, action) => {
            state.circle = action.payload
        }
    }
})

export default circleSlice.reducer
export const addCircle = circleSlice.actions