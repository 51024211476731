import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Avatar, Badge, CircularProgress } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import { pictos } from '../assets/img/pictos';
import { toLower, upperFirst } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateProfil } from '../redux/updatesServices';
import '../styles/compte.css'
import Navigation from '../components/Navigation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { profilRedux, refreshToken } from '../redux/profilService';
import ErrorPopup from '../components/ErrorPopup';
import Chemin from '../components/Chemin';
import textConnect from '../textConnect.json'



const Compte = () => {
    // const [monProfil, setMonProfil] = useState(JSON.parse(localStorage.getItem('profilUser')))
    const monProfil = useSelector(state => state.profil.profil)
    const accessToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])

    function reseauSociaux(reseaux) {
        var tabRetour = [];

        for (let index = 0; index < reseaux.length; index++) {

            if (reseaux[index].nom === "link") {
                tabRetour.push({ logo: pictos.linkedin_icone, url: reseaux[index].url })
            }
            if (reseaux[index].nom === "twi") {
                tabRetour.push({ logo: pictos.twitter_icone, url: reseaux[index].url })
            }
        }

        return tabRetour
    }

    function categories(cat) {
        var tabRetour = [{ icone: pictos.modifier_icone, click: true }];

        for (let index = 0; index < cat.length; index++) {

            if (cat[index] === "Exposant") {
                tabRetour.push({ icone: pictos.speaker_icone, click: false })
            }
            if (cat[index] === "Congressiste") {
                tabRetour.push({ icone: pictos.congressiste_icone, click: false })
            }
            if (cat[index] === "Intervenant") {
                tabRetour.push({ icone: pictos.intervenant_icone, click: false })
            }
            if (cat[index] === "Visiteur") {
                tabRetour.push({ icone: pictos.visiteur_icone, click: false })
            }

        }

        return tabRetour
    }



    //Modal Update photo
    const [showPhotoUpdate, setShowPhotoUpdate] = useState(false)
    const handleClosePhoto = () => {
        setImageModif(undefined)
        setShowPhotoUpdate(false)
        setCircleModif(false)
    };
    const handleShowPhoto = () => setShowPhotoUpdate(true);


    //Allow Modification
    const [modification, setModification] = useState(false)

    const allowModif = () => {
        setModification(true)
        setPresentation(monProfil.presentation)
        setTwitterModif(monProfil.reseausociaux[0].url === 'null' ? '' : monProfil.reseausociaux[0].url)
        setLinkedinModif(monProfil.reseausociaux[1].url === 'null' ? '' : monProfil.reseausociaux[1].url)
    }




    const [presentation, setPresentation] = useState('')
    const [twitterModif, setTwitterModif] = useState('')
    const [linkedinModif, setLinkedinModif] = useState('')
    const [imageModif, setImageModif] = useState()
    const [circleModif, setCircleModif] = useState(false)
    const [circleModif2, setCircleModif2] = useState(false)

    const dispatch = useDispatch()





    const recupPhoto = () => {

        setCircleModif(true)
        const image = imageModif;
        const imgData = new FormData()

        imgData.append("photo", image);

        UpdateProfil(imgData, monProfil.id, accessToken).then((reponse) => {
            // console.log(reponse)
            dispatch(
                {
                    type: 'Profil/addProfil',
                    payload: reponse.data
                }
            );
            setCircleModif(false)
            setShowPhotoUpdate(false)
            setModification(false)


        }).catch((erreur) => {
            setLoading(false)
            setCircleModif(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }

                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );

                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )

                            console.log('token refresh...')

                        })
                }
            }
        })
        // setShowPhotoUpdate(false)

    }




    async function modifInfos(valTwitter, valLinkedin, valPresentation) {
        setCircleModif2(true)

        const dataUpdate = new FormData()
        // const presentData = new FormData()

        dataUpdate.append('presentation', valPresentation)
        dataUpdate.append('twitter_social_networks', valTwitter)
        dataUpdate.append('linkedin_social_networks', valLinkedin)

        UpdateProfil(dataUpdate, monProfil.id, accessToken).then((reponse) => {
            dispatch(
                {
                    type: 'Profil/addProfil',
                    payload: reponse.data
                }

            );

            setModification(false)
            setCircleModif2(false)



        }).catch((erreur) => {
            setCircleModif2(false)
            // setModification(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)

            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );

                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )

                            console.log('token refresh...')

                        })
                }
            }
        })

        // if (valTwitter !== '' || valLinkedin !== '') {
        //     setCircleModif2(true)
        //     if (valPresentation !== '') {
        //         reseauData.append('presentation', presentation)

        //     }
        //     if (valTwitter !== '' && valLinkedin !== '') {
        //         reseauData.append('twitter_social_networks', valTwitter)
        //         reseauData.append('linkedin_social_networks', valLinkedin)
        //     }
        //     if (valTwitter !== '' && valLinkedin === '') {
        //         reseauData.append('twitter_social_networks', valTwitter)
        //     }
        //     if (valTwitter === '' && valLinkedin !== '') {

        //         reseauData.append('linkedin_social_networks', valLinkedin)
        //     }

        //     UpdateProfil(reseauData, monProfil.id, accessToken).then((reponse) => {
        //         setTimeout(() => {
        //             setModification(false)
        //             setCircleModif2(false)
        //         }, 1000);
        //     }).catch((erreur) => {
        //         setCircleModif2(false)
        //         setModification(false)
        //         if (!netStatus) {
        //             setMsgError(textConnect.internet)
        //             setOpenError(true)
        //         }
        //         if (erreur.response) {
        //             if (erreur.response.status !== 401) {
        //                 setMsgError(textConnect.error)
        //                 setOpenError(true)
        //             }
        //             if (erreur.response.status === 401) {
        //                 refreshToken(refresh_token).then(
        //                     async (reponse) => {
        //                         dispatch(
        //                             {
        //                                 type: 'user/addRefresh',
        //                                 payload: reponse.data.refresh
        //                             }
        //                         );

        //                         dispatch(
        //                             {
        //                                 type: 'user/addAccess',
        //                                 payload: reponse.data.access
        //                             }
        //                         )

        //                         console.log('token refresh...')

        //                     })
        //             }
        //         }
        //     })

        // }
        // if (valTwitter === '' && valLinkedin === '') {
        //     if (valPresentation !== '') {
        //         setCircleModif2(true)
        //         presentData.append('presentation', presentation)
        //         UpdateProfil(presentData, monProfil.id, accessToken).then((reponse) => {
        //             // dispatch(
        //             //     {
        //             //         type: 'Profil/addProfil',
        //             //         payload: reponse.data
        //             //     }
        //             // );


        //             setTimeout(() => {
        //                 setModification(false)
        //                 setCircleModif2(false)
        //             }, 1000);

        //         }).catch((erreur) => {
        //             setCircleModif2(false)
        //             setModification(false)
        //             if (!netStatus) {
        //                 setMsgError(textConnect.internet)
        //                 setOpenError(true)
        //             }
        //             if (erreur.response) {
        //                 if (erreur.response.status !== 401) {
        //                     setMsgError(textConnect.error)
        //                     setOpenError(true)
        //                 }
        //                 if (erreur.response.status === 401) {
        //                     refreshToken(refresh_token).then(
        //                         async (reponse) => {
        //                             dispatch(
        //                                 {
        //                                     type: 'user/addRefresh',
        //                                     payload: reponse.data.refresh
        //                                 }
        //                             );

        //                             dispatch(
        //                                 {
        //                                     type: 'user/addAccess',
        //                                     payload: reponse.data.access
        //                                 }
        //                             )

        //                             console.log('token refresh...')

        //                         })
        //                 }
        //             }
        //         })

        //     }
        // }

    }
    // const partenaireSelect = async (id) => {
    //     setLoading(true)
    //     partenairesRedux(accessToken, evenementSelected.id).then((reponse) => {
    //         console.log(reponse.data.filter(item => item.id_organisation === id))
    //         dispatch(
    //             {
    //                 type: 'evenements/addExposantSelect',
    //                 payload: reponse.data.filter(item => item.id_organisation === id)[0]
    //             }
    //         )
    //         if (reponse.data.filter(item => item.id_organisation === id).length === 1) {
    //             navigationPage(`/partenaire/${id}`)
    //             window.scrollTo(0, 0)
    //         }
    //         else {
    //             setMsgError("L'organisation n'a pas de fiche profil")
    //             setOpenError(true)
    //         }


    //         setLoading(false)
    //     }).catch((erreur) => {
    //         setLoading(false)
    //         if (erreur.request) {
    //             if (erreur.request.status === 0) {
    //                 // console.log(erreur.request)
    //                 setMsgError('Vérifiez votre connexion internet')
    //                 setOpenError(true)
    //             }
    //         }
    //         if (erreur.response) {
    //             if (erreur.response.status !== 401) {
    //                 setMsgError(textConnect.error)
    //                 setOpenError(true)
    //             }
    //         }
    //     })

    //     // dispatch(
    //     //     {
    //     //         type: 'evenements/addExposantSelect',
    //     //         payload: contenu
    //     //     }
    //     // );

    //     // navigationPage(`/partenaire/${id}`)
    //     // window.scrollTo(0, 0)

    // }
    const location = useLocation()
    const [loading, setLoading] = useState(true)

    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')

    const closeErrorHandle = () => {
        setOpenError(false)
    }
    //Breadcrumbs
    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.nom === 'Accueil'))
    breadcrumbs_temp.push({ url: location.pathname, nom: 'Mon profil' })
    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })


    }, [])

    useEffect(() => {
        // setLoading(true)
        profilRedux(monProfil.id, accessToken).then((reponse) => {
            // console.log(reponse.data)
            setLoading(false)
            dispatch(
                {
                    type: 'Profil/addProfil',
                    payload: reponse.data
                }
            );

        }).catch((erreur) => {
            setLoading(false)
            // setModification(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );

                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )

                            console.log('token refresh...')

                        })
                }
            }
        })
    }, [])


    return (
        <div>
            <Container fluid>
                <Row>
                    <Navigation
                        button={false}
                        titre={'Mon profil'}
                        photo={monProfil.photo_path}
                        retour='dashboard'
                        profile={true}
                        menus={true}
                    />
                </Row>

                <Row className='p-0'>
                    <Chemin
                        breadCrumbs={breadcrumbs_temp}
                    />

                </Row>
                <ErrorPopup
                    message={msgError}
                    open={openError}
                    close={() => closeErrorHandle()}
                />
                {/* Modal de modification photo de profil */}
                <Modal show={showPhotoUpdate} style={{ fontFamily: 'Poppins' }} className='modal-photo'>
                    <form>
                        <Modal.Header className='h4' style={{ color: 'white', backgroundColor: '#271d67' }}>
                            Modifier la photo de profil
                        </Modal.Header>
                        <Modal.Body>
                            <input onChange={(e) => setImageModif(e.target.files[0])} accept='.jpeg, .JPG, .png, .PNG' type="file" id="imgSelect" title='Choisir une photo' />
                        </Modal.Body>
                        <Modal.Footer>
                            {circleModif ? <CircularProgress size={'1rem'} color='primary' /> : <Button className='' disabled={imageModif === undefined ? true : false} onClick={() => recupPhoto()}>{'Modifier'}</Button>}

                            <Button variant='secondary' onClick={handleClosePhoto}>Fermer</Button>
                        </Modal.Footer>
                    </form>

                </Modal>

                {loading ?
                    <div className='d-flex justify-content-center'>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <Row style={{}} className='infos-profil d-flex' >

                            <div className='container-gauche'>
                                <Container>
                                    <Row className='' style={{}}>
                                        <Col xs={100} className='mb-2 p-0'>
                                            <Badge style={{ width: '100% !important' }}
                                                overlap='circular'
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={[
                                                    categories(monProfil.categories).map((item, index) =>
                                                        <Avatar key={index} onClick={() => setShowPhotoUpdate(item.click)} className='' src={item.icone} alt="" style={{ width: '30px', height: '30px', marginRight: '', backgroundColor: '  white' }} />
                                                    )]


                                                }

                                            >
                                                <NavLink>
                                                    <Avatar className='avatar-profil' onClick={handleShowPhoto} src={monProfil.photo_path} style={{ width: '100px', height: '100px', backgroundColor: '' }} />
                                                    {/* <img src={monProfil.photo} alt="" style={{ width: '100px', height: '100px', backgroundColor: '' }} /> */}
                                                    {/* <Avatar onClick={handleShowPhoto} src={PROFIL_TEST} style={{ width: '100px', height: '100px', backgroundColor: '' }} /> */}
                                                </NavLink>


                                            </Badge>
                                        </Col>
                                        <Col className='nom-profil1 p-0'>
                                            <Row className='d-flex align-items-center'>
                                                <Col className='profil-nom'>{upperFirst(monProfil.user.prenom.toLowerCase()) + ' ' + upperFirst(monProfil.user.nom.toLowerCase())}</Col>

                                            </Row>
                                            <Row className=''>
                                                <Col>{upperFirst(monProfil.fonction.toLowerCase())} <br />{monProfil.raison_sociale === '' || monProfil.raison_sociale === null ? !monProfil.from_structure ? upperFirst(monProfil.organisation.nom.toLowerCase()) : monProfil.organisation.adresse_organisation.ville : upperFirst(monProfil.raison_sociale)}</Col>
                                            </Row>

                                        </Col>
                                    </Row>

                                    <Row className='coordonnees-container' style={{ margin: '', textAlign: '' }}>
                                        <Col className=''>
                                            {/* <div className='h5'><b>{upperFirst(monProfil.user.prenom) + ' ' + upperFirst(monProfil.user.nom)}</b> </div> */}
                                            <Row className='coordonnee-info'>
                                                <Col className='p-0 coordonnees-titre mt-2'>Coordonnées</Col>
                                            </Row>
                                            <Row className='d-flex align-items-center'>

                                                {monProfil.coordonnes.telephone.telephone_fixe === null || monProfil.coordonnes.telephone.telephone_fixe === 'None' ?
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        <Col className='p-0 icone' sm='auto'>
                                                            <img src={pictos.tel_icone} alt="" />
                                                        </Col>
                                                        <Col className='p-0'>
                                                            {monProfil.coordonnes.telephone.telephone_fixe}
                                                        </Col>
                                                    </>

                                                }

                                            </Row>
                                            <Row className='d-flex align-items-center'>
                                                {monProfil.coordonnes.telephone.telephone_portable === null || monProfil.coordonnes.telephone.telephone_portable === 'None' ?
                                                    <>
                                                    </>
                                                    :
                                                    <>
                                                        <Col className='p-0 icone mt-2' sm='auto'>
                                                            <img src={pictos.tel_icone} alt="" />
                                                        </Col>
                                                        <Col className='p-0'>
                                                            {monProfil.coordonnes.telephone.telephone_portable}
                                                        </Col>
                                                    </>

                                                }

                                            </Row>
                                            <Row className='d-flex align-items-center'>
                                                <Col className='p-0 icone mt-2' sm='auto'>
                                                    <img src={pictos.mail_icone} alt="" />
                                                </Col>
                                                <Col className='p-0'>
                                                    {monProfil.coordonnes.email}
                                                </Col>
                                            </Row>


                                            <Row className='d-flex align-items-center'>
                                                <Col className='p-0'>
                                                    {reseauSociaux(monProfil.reseausociaux).map((item, index) =>
                                                        item.url !== null && item.url !== 'null' && item.url !== undefined ?
                                                            item.url !== '' ?
                                                                <div key={index} className='icone mt-2' style={{ display: 'inline-block', width: '35px' }}>
                                                                    <NavLink to={item.url} target='_blank'>
                                                                        <img src={item.logo} alt="" />
                                                                    </NavLink>

                                                                </div>
                                                                :
                                                                null
                                                            :
                                                            null
                                                    )}
                                                </Col>
                                            </Row>


                                            {modification ?
                                                <Row>
                                                    <Row>
                                                        <input onChange={(e) => setTwitterModif(e.target.value === '' ? 'null' : e.target.value)} className='border form-control mt-2' type="text" defaultValue={monProfil.reseausociaux[0].url === 'null' ? '' : monProfil.reseausociaux[0].url} placeholder="Coller l'url twitter" style={{ width: '100%' }} />
                                                    </Row>
                                                    <Row>
                                                        <input onChange={(e) => setLinkedinModif(e.target.value === '' ? 'null' : e.target.value)} className='border form-control mt-2' type="text" defaultValue={monProfil.reseausociaux[1].url === 'null' ? '' : monProfil.reseausociaux[1].url} placeholder="Coller l'url linkedin" />

                                                    </Row>
                                                </Row>
                                                : <></>
                                            }

                                            <Row>
                                                <Col className='mt-2 p-0'>
                                                    {
                                                        monProfil.from_structure ?
                                                            null
                                                            :
                                                            monProfil.organisation.site_web !== '' ?
                                                                <NavLink to={"http://" + monProfil.organisation.site_web} target='_blank'>
                                                                    <img src={monProfil.organisation.logo} alt="logo" height={'80px'} style={{ margin: 'auto' }} />
                                                                </NavLink>
                                                                :
                                                                <>
                                                                    <img src={monProfil.organisation.logo} alt="logo" height={'80px'} style={{ margin: 'auto' }} />
                                                                </>
                                                    }

                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>

                            </div>
                            <div className='container-droite'>
                                <Container>
                                    <Row className='profil-coordonnees' style={{ marginTop: '12px', marginBottom: '12px' }}>
                                        <Row className='d-flex align-items-center justify-content-end'>
                                            <Col className='profil-nom nom-profil2 '>
                                                <Row>
                                                    <Col className='p-0'>
                                                        {upperFirst(toLower(monProfil.user.prenom)) + ' ' + upperFirst(toLower(monProfil.user.nom))}
                                                    </Col>

                                                </Row>

                                            </Col>
                                            <Col sm='auto' className='p-0 d-flex align-items-center justify-content-end' style={{ width: 'fit-content' }}>
                                                {!modification ?
                                                    <Button disabled={modification} className='btn-modifier' onClick={() => allowModif()}>
                                                        Modifier
                                                    </Button>
                                                    :
                                                    null
                                                }
                                            </Col>
                                        </Row>

                                        <Row className='nom-profil2 '>
                                            <Col>
                                                <Row>
                                                    <Col className='p-0'>
                                                        {upperFirst(monProfil.fonction.toLowerCase())}
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col className='p-0'>
                                                        {monProfil.raison_sociale === '' || monProfil.raison_sociale === null ? !monProfil.from_structure ? upperFirst(monProfil.organisation.nom.toLowerCase()) : upperFirst(monProfil.organisation.adresse_organisation.ville.toLowerCase()) : upperFirst(monProfil.raison_sociale.toLowerCase())}<i style={{ opacity: '0' }}>.</i>
                                                    </Col>

                                                </Row>

                                            </Col>
                                        </Row>
                                    </Row>


                                    <Row className='coordonnee-info'>
                                        <Row className="coordonnees-titre-infos">
                                            <Col className="mt-2 p-0">Présentation</Col>
                                        </Row>
                                        <Row className="" style={{ textAlign: 'left' }}>
                                            {modification ?
                                                <Col className='p-0'>
                                                    <ReactQuill onChange={(e) => setPresentation(e)} value={upperFirst(presentation)} defaultValue={upperFirst(monProfil.presentation)} style={{}} />
                                                    {/* <textarea onChange={(e) => setPresentation(e.target.value)} className='form-control mb-1' type="text" defaultValue={upperFirst(monProfil.presentation)} maxLength={1000} style={{ height: '300px' }} /> */}
                                                </Col>
                                                :
                                                monProfil.presentation === '' || monProfil.presentation === null ?
                                                    null :
                                                    <Col className='p-0' style={{ wordBreak: 'break-word', height: 'fit-content' }} dangerouslySetInnerHTML={{ __html: upperFirst(monProfil.presentation) }}>

                                                    </Col>
                                            }

                                        </Row>

                                    </Row>

                                    {monProfil.expertises.length ?
                                        <Row className='coordonnee-info'>
                                            <Row className="coordonnees-titre-infos">
                                                <Col className="coordonnees-titre mt-2 p-0">Expertises</Col>
                                            </Row>
                                            {monProfil.expertises.map((item, index) =>

                                                <Row key={index} >
                                                    <Col sm='auto p-0' className="list-profil">• {upperFirst(toLower(item))}</Col>

                                                </Row>

                                            )}

                                        </Row>
                                        :
                                        null
                                    }

                                    {monProfil.centreinterets.length ?
                                        <Row className='coordonnee-info'>
                                            <Row className="coordonnees-titre-infos">

                                                <Col className="mt-2 p-0">Centres d'intêrets</Col>

                                            </Row>
                                            {monProfil.centreinterets.map((item, index) =>
                                                <Row key={index} className="">
                                                    <Col className='p-0'>• {upperFirst(toLower(item))}</Col>

                                                </Row>
                                            )}


                                        </Row>
                                        :
                                        null
                                    }


                                    <Row className='coordonnee-info'>

                                    </Row>
                                    {modification ?
                                        <Row className=''>
                                            <Row className='d-flex justify-content-end mb-3'>
                                                <Col style={{ width: 'fit-content' }} className='p-0' sm='auto'>{circleModif2 ? <CircularProgress style={{ marginLeft: '25%', color: '#009af4' }} /> : <Button className='btn-valider' onClick={() => modifInfos(twitterModif, linkedinModif, presentation)}>Valider</Button>}</Col>
                                            </Row>

                                        </Row>
                                        :
                                        null
                                    }

                                </Container>
                            </div>
                            <Col className='coordonnees-container2'></Col>
                        </Row>
                    </>
                }


            </Container >
        </div >
    );
};

export default Compte;