import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import { pictos } from '../assets/img/pictos';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import '../styles/plan.css'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { CircularProgress } from '@mui/joy';
import Breadcrumb from '../components/Breadcrumb';
import { useEffect } from 'react';
import Chemin from '../components/Chemin';

const PlanStand = () => {
    const evenementSelected = useSelector(state => state.evenements.evenementSelect)
    const monProfil = useSelector(state => state.profil.profil)
    const plan = useSelector(state => state.evenements.planSelected)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const param = useParams()
    const location = useLocation()

    const dispatch = useDispatch()

    //Breadcrumbs
    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.url.split('/')[1] !== 'planStand'))
    breadcrumbs_temp.push({ url: location.pathname, nom: 'Plan des stands' })

    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })
    }, [])

    return (

        <Container fluid className=''>
            <Row>
                <Navigation
                    button={true}
                    titre={'Plan des stands'}
                    icone={pictos.plan_stand_rond_icone}
                    evenement={evenementSelected.photo}
                    photo={monProfil.photo_path}
                    retour='dashboard'
                    selected={5}
                    open={(!open).toString()}
                    menus={true}

                />
            </Row>

            <Row className='p-0'>
                <Chemin
                    breadCrumbs={breadcrumbs_temp}
                />

            </Row>
            <Row className='vh-100 mt-0 d-flex justify-content-center align-items-center ratio ratio-16x9'>
                <div className='d-flex justify-content-center align-items-center'>
                    <CircularProgress />
                </div>

                <iframe className='iframe-carte  m-auto' width={'fit-content'} height={'fit-content'} src={param.idPartenaire === '0' ? plan : `${plan}/${param.idPartenaire}`} frameBorder="0" title='stand' loading='lazy' style={{ position: 'absolute' }}></iframe>


            </Row>

        </Container>

    );
};

export default PlanStand;