import React from "react";
import { Input } from "@mui/joy";
import { Col, Row } from "react-bootstrap";
import { SearchOutlined } from "@material-ui/icons";

const SearchInput = (props) => {
  return (
    <Input
      onChange={(e) => props.filtre(e)}
      className="col"
      color="#271d67"
      variant="outlined"
      startDecorator={<SearchOutlined />}
      placeholder={props.placeholder}
      sx={{
        "--Input-radius": "50px",
        backgroundColor: "#e4e4e4",
        width: "",
        fontFamily: "Poppins",
        color: "#271d67",
      }}
      type="search"
      value={props.value}
    />
  );
};

export default SearchInput;
