import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    evenements: [],
    evenementSelect: '',
    participants: [],
    participantSelect: '',
    programmeSelect: {},
    programmes: {},
    maJournee: {},
    exposants: [],
    exposantsPremium: [],
    exposantSelect: {},
    planSelected: ''

}

const evenementSlice = createSlice({
    name: 'evenements',
    initialState,
    reducers: {
        addEvenements: (state, action) => {
            state.evenements = action.payload
        },
        addEvenementSelect: (state, action) => {
            state.evenementSelect = action.payload
        },
        addParticipants: (state, action) => {
            state.participants = action.payload
        },
        addParticipantSelect: (state, action) => {
            state.participantSelect = action.payload
        },
        addProgrammeSelect: (state, action) => {
            state.programmeSelect = action.payload
        },
        addProgramme: (state, action) => {
            state.programmes = action.payload
        },
        addMajournee: (state, action) => {
            state.maJournee = action.payload
        },
        addExposant: (state, action) => {
            state.exposants = action.payload
        },
        addExposantPremium: (state, action) => {
            state.exposantsPremium = action.payload
        },
        addExposantSelect: (state, action) => {
            state.exposantSelect = action.payload
        },
        addPlan: (state, action) => {
            state.planSelected = action.payload
        },
        resetEvenements: () => initialState
    }
})

export const { addEvenements, addEvenementSelect, addParticipants, addParticipantSelect, addProgramme, addProgrammeSelect, addMajournee, addExposant, addExposantSelect, resetEvenements, addExposantPremium, addPlan } = evenementSlice.actions;
export default evenementSlice.reducer;