import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, FormSelect, Modal, Row } from "react-bootstrap";
import ChatSend from "../components/ChatSend";
import ChatReceive from "../components/ChatReceive";
import { Badge, Avatar, Box, IconButton, Textarea } from "@mui/joy";
import { CalendarToday, CalendarTodayOutlined, Delete, Send } from "@material-ui/icons";
import { pictos } from "../assets/img/pictos";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { SOCKETURL, URL } from "../redux/api";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { NotificationsRedux, creerRdv, listMessage, refreshToken } from "../redux/profilService";
import axios from "axios";
import { CircularProgress, TextField } from "@material-ui/core";
import Navigation from "../components/Navigation";
import _, { toLower, upperFirst } from 'lodash';
import { UpdateNotification } from "../redux/updatesServices";
import { NotifNbreContext } from "../Context";
import { affichageDate } from "./fonctions";
import Breadcrumb from "../components/Breadcrumb";
import Chemin from "../components/Chemin";
import ErrorPopup from "../components/ErrorPopup";
import { TextareaAutosize } from "@mui/base";
import textConnect from '../textConnect.json'

const Chatbox = () => {
  const monProfil = useSelector((state) => state.profil.profil);
  const AccessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector(state => state.user.refresh)

  const user_id = useSelector(state => state.profil.profil.id);
  const chat_id = useSelector(state => state.profil.idConversation);

  const listeNotifications = useSelector(state => state.profil.notifications)

  const notifChat = listeNotifications.filter(item => item.id_target === chat_id)


  const [eventId, setEventId] = useState("")
  const [heureDebut, setHeureDebut] = useState("")
  const [heureFin, setHeureFin] = useState("")
  const [sujetRdv, setSujetRdv] = useState("")
  const [lesRendezvous, setLesRendezvous] = useState("")
  const [descriptionRdv, setDescriptionRdv] = useState("")
  const [lieuRdv, setLieuRdv] = useState("")
  const [standRdv, setStandRdv] = useState("")
  const [idDestRdv, setIdDestRdv] = useState("")

  const choixEvent = async (e) => {
    setEventId(e.target.value)
    await axios.get(`${URL}/api/stand/${e.target.value}`, {
      headers: {
        'Authorization': 'JWT ' + AccessToken
      }

    }).then((response) => {
      dispatch(
        {
          type: 'Profil/standChat',
          payload: response.data
        }
      )
    })
  }

  const [circular, setCircular] = useState(false)
  const [loading, setLoading] = useState(true)

  function standLieu(e) {
    setStandRdv(e.target.value)
    // console.log(e.target.value)
    // console.log(e.target.selectedOptions[0].text)
    // setLieuRdv(e.target.selectedOptions[0].text)
  }

  const user_chat = useSelector(state => state.profil.chat);
  const [message, setMessage] = useState('');
  const [messageShow, setMessageShow] = useState('');
  const param = useParams();
  const [messageFilDisc, setMessageFilDisc] = useState([]);
  const [contact, setContact] = useState();
  const { readyState, sendJsonMessage } = useWebSocket(
    `${SOCKETURL}/chat/${param.room}/?token=${AccessToken}`,
    {
      onOpen: () => {
        console.log("Connected!");
      },
      onClose: () => {
        console.log("Disconnected!");
      },
      onMessage: (e) => {
        const data = JSON.parse(e.data);
        let chat = document.getElementById('container-chat')
        switch (data.type) {
          case "chat_message":
            setMessageFilDisc((prev) => prev.concat(data));

            setTimeout(() => {
              if (chat.scrollHeight === null) {
                chat.scrollTop = 0
              }
              else {
                chat.scrollTop = chat.scrollHeight
              }
              // chat.scrollTop = chat.scrollHeight === null ? 0 : chat.scrollHeight
            }, 200);
            // console.log('la data : ', data)
            setLoading(false)
            break;
          default:
            // console.log("Unknown message type!", data);
            break;
        }
      },
    }
  );
  const navigationPage = useNavigate()
  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };

  }, [netStatus])
  //Suppression du fil de discussion
  const deleteFilDisc = async (id) => {
    await axios.post(`${URL}/api/delete-conversation`, {
      conversation: id
    }, {
      headers: {
        'Authorization': 'JWT ' + AccessToken
      }
    }).then(
      navigationPage('/messagerie')
    ).catch(
      // console.log('erreur de suppression fil de discussion')
      setMsgError('erreur de suppression fil de discussion'),
      setOpenError(true)
    )
  }


  const [openError, setOpenError] = useState(false)
  const [msgError, setMsgError] = useState('')

  const closeErrorHandle = () => {
    setOpenError(false)
  }


  const nbreNotif = useContext(NotifNbreContext)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: 'Profil/addChemin',
      payload: breadcrumbs_temp
    })

    listMessage(AccessToken, param.chatid).then(async (data) => {
      setMessageFilDisc([...data.messages])
      setLesRendezvous(data.rendezvous)
      setLoading(false)
      setTimeout(() => {
        let chat = document.getElementById('container-chat')
        if (chat.scrollHeight === null) {
          chat.scrollTop = 0
        }
        else {
          chat.scrollTop = chat.scrollHeight
        }
      }, 300);

      let contacts = data.conversation
      if (contacts.participant_1.id == user_id) {
        setContact(upperFirst(contacts.participant_2.nom.toLowerCase()))
        setIdDestRdv(contacts.participant_2.id)
      } else {
        setContact(upperFirst(contacts.participant_1.nom.toLowerCase()))
        setIdDestRdv(contacts.participant_1.id)
      }

    }).catch((erreur) => {
      setLoading(false)
      if (!netStatus) {
        setMsgError(textConnect.internet)
        setOpenError(true)
      }
      if (erreur.response) {
        if (erreur.response.status !== 401) {
          setMsgError(textConnect.error)
          setOpenError(true)
        }
        if (erreur.response.status === 401) {
          refreshToken(refresh_token).then(
            async (reponse) => {
              dispatch(
                {
                  type: 'user/addRefresh',
                  payload: reponse.data.refresh
                }
              );
              dispatch(
                {
                  type: 'user/addAccess',
                  payload: reponse.data.access
                }
              )
              console.log('token refresh...')

              setLoading(false)
              // handleContacts()
            })



        }
      }
    })





  }, [])

  useEffect(() => {


    // console.log(listeNotifications[0].id_target)
    if (notifChat.length !== 0) {
      notifChat.map((item) =>
        UpdateNotification(item.id, AccessToken).then((response) => {
          NotificationsRedux(AccessToken).then((reponse) => {
            dispatch(
              {
                type: 'Profil/addNotifications',
                payload: reponse.data.filter(item =>
                  item.participant_recepteur.id === monProfil.id && item.etat === false
                )
              }
            );
            nbreNotif.setNbre(reponse.data.filter(item =>
              item.participant_recepteur.id === monProfil.id && item.etat === false
            ).length)
          })
        })
      )

    }
  }, [notifChat])




  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  const handleClick = (e) => {

    sendJsonMessage({
      type: "chat_message",
      message: document.getElementById('messageText').value.trim(),
    });
    setMessage("")
    document.getElementById('messageText').value = ''
  };

  const msgText = (e) => {
    document.getElementById('messageText').value.trim() !== '' ? setMessage('message') : setMessage('')

    window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "instant" });

  }

  const [openSupprimer, setOpenSupprimer] = useState(false)
  const [openRdv, setOpenRdv] = useState(false)
  const [openAcceptRdv, setOpenAcceptRdv] = useState(false)

  // const [selectChat, setSelectChat] = useState()
  const fermerRdv = () => {
    setOpenRdv(false)
    setEventId('')
    setHeureDebut('')
    setHeureFin('')
    setSujetRdv('')
    setDescriptionRdv('')
    setLieuRdv('')
    setStandRdv('')
    setIdDestRdv('')
  }
  const ouvrirSupprimer = (id) => {
    // setSelectChat(id)
    setOpenSupprimer(true)
    // console.log(id)
  }
  //JONATHAN ADD
  const handleDeleteClick = (id) => {
    sendJsonMessage({
      type: "delete",
      id: id,
    });
  };
  const evenementsChat = useSelector(state => state.profil.chatEvenement)
  const standsChat = useSelector(state => state.profil.chatStand)

  // const acceptRdvHandle = (rdv_id) => {
  //   const notifRdv = listeNotifications.filter(item => item.id_target === rdv_id)
  //   try {
  //     accepterRdv(lesRendezvous.programme.id, lesRendezvous.destinateur, AccessToken, param.chatid, setOpenAcceptRdv, setLesRendezvous)
  //     if (notifRdv.length !== 0) {
  //       UpdateNotification(notifRdv[0].id, AccessToken).then((response) => {
  //         NotificationsRedux(AccessToken)
  //           .then((reponse) => {
  //             dispatch(
  //               {
  //                 type: 'Profil/addNotifications',
  //                 payload: reponse.data.filter(item =>
  //                   item.participant_recepteur.id === monProfil.id && item.etat === false
  //                 )
  //               }
  //             );
  //             nbreNotif.setNbre(reponse.data.filter(item =>
  //               item.participant_recepteur.id === monProfil.id && item.etat === false
  //             ).length)
  //           })
  //       })
  //     }


  //   } catch (error) {
  //     console.log('erreur acceptation rendez-vous')
  //   }

  // }
  // const refuseRdvHandle = (rdv_id) => {
  //   const notifRdv = listeNotifications.filter(item => item.id_target === rdv_id)
  //   try {
  //     refuserrRdv(lesRendezvous.programme.id, lesRendezvous.destinateur, AccessToken, param.chatid, setOpenAcceptRdv, setLesRendezvous)
  //     if (notifRdv.length !== 0) {
  //       UpdateNotification(notifRdv[0].id, AccessToken).then((response) => {
  //         NotificationsRedux(AccessToken)
  //           .then((reponse) => {
  //             dispatch(
  //               {
  //                 type: 'Profil/addNotifications',
  //                 payload: reponse.data.filter(item =>
  //                   item.participant_recepteur.id === monProfil.id && item.etat === false
  //                 )
  //               }
  //             );
  //             nbreNotif.setNbre(reponse.data.filter(item =>
  //               item.participant_recepteur.id === monProfil.id && item.etat === false
  //             ).length)
  //           })
  //       })
  //     }


  //   } catch (error) {
  //     console.log('erreur acceptation rendez-vous')
  //   }

  // }
  const location = useLocation()
  //Breadcrumbs
  const breadCrumbs = useSelector(state => state.profil.chemin)
  const breadcrumbs_temp = breadCrumbs.filter((item => upperFirst(item.nom.toLowerCase()) !== upperFirst(contact) && item.url.split('/')[1] !== 'planStand'))
  breadcrumbs_temp.push({ url: location.pathname, nom: upperFirst(contact) })

  const creationRdv = () => {
    setCircular(true)
    creerRdv(AccessToken, eventId, heureDebut, heureFin, sujetRdv, descriptionRdv, lieuRdv, standRdv, idDestRdv).then((reponse) => {

      setCircular(false)

      setEventId('')
      setHeureDebut('')
      setHeureFin('')
      setSujetRdv('')
      setDescriptionRdv('')
      setLieuRdv('')
      setStandRdv('')
      setIdDestRdv('')

      setOpenRdv(false)
      // window.location.reload()
      navigationPage(0)
    }).catch((erreur) => {
      setCircular(false)
      if (!netStatus) {
        setMsgError(textConnect.internet)
        setOpenError(true)
      }
      if (erreur.response) {
        if (erreur.response.status !== 401) {
          setMsgError(textConnect.error)
          setOpenError(true)
        }

        if (erreur.response.status === 401) {
          refreshToken(refresh_token).then(
            async (reponse) => {
              dispatch(
                {
                  type: 'user/addRefresh',
                  payload: reponse.data.refresh
                }
              );
              dispatch(
                {
                  type: 'user/addAccess',
                  payload: reponse.data.access
                }
              )
              console.log('token refresh...')
              creationRdv()
              // setLoading(false)
              // handleContacts()
            })



        }

      }
    })


  }

  return (
    <Container fluid>

      {/* Modal confirmation suppression discussion */}
      <Modal show={openSupprimer} style={{ fontFamily: 'Poppins' }} onHide={() => setOpenSupprimer(false)}>
        <Modal.Header closeVariant='white' closeButton style={{ color: 'white', backgroundColor: '#271d67' }}>
          <h4>

            Confirmation

          </h4>
        </Modal.Header>
        <form>
          <Modal.Body>

            Voulez-vous vraiment supprimer ce fil de discussion ?


            <div className='d-flex justify-content-end mt-3'>

              <Button className='btn-recup' onClick={() => deleteFilDisc(param.chatid)}>Confirmer</Button>

              <Button className='btn-recup2 mx-2' variant='secondary' onClick={() => setOpenSupprimer(false)}>Fermer</Button>
            </div>

          </Modal.Body>
        </form>
      </Modal>


      {/* Modal créer un rendez-vous */}
      <Modal show={openRdv} style={{ fontFamily: 'Poppins' }} onHide={() => fermerRdv()}>
        <Modal.Header closeVariant='white' closeButton style={{ color: 'white', backgroundColor: '#271d67' }}>
          <h4>

            Créer un rendez-vous

          </h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            {/* disabled={lesRendezvous.destinateur === monProfil.id || lesRendezvous.emeteur === monProfil.id ? true : false} */}
            {lesRendezvous.destinateur === monProfil.id || lesRendezvous.emeteur === monProfil.id ?

              <div className="py-3">
                Vous avez déjà un rendez-vous en attente !
              </div>
              :
              <Form>
                <Row className='mb-2'>
                  <Col>
                    <FormSelect onChange={(e) => choixEvent(e)}>
                      <option value="" >Choisir un événement</option>
                      {evenementsChat.map((item, index) =>
                        <option key={index} value={item.id}>{item.nom}</option>
                      )}


                    </FormSelect>
                  </Col>
                </Row >
                <Row className='mb-2'>
                  <Col>
                    <FormControl className="border" onChange={(e) => setSujetRdv(e.target.value)} placeholder='Saisir le sujet du rendez-vous' ></FormControl></Col>
                </Row >
                <Row className='mb-2'>
                  <Col>
                    <FormControl className="border" onChange={(e) => setDescriptionRdv(e.target.value)} placeholder='Saisir la description du rendez-vous' ></FormControl></Col>
                </Row >
                <Row className='mb-2'>

                  <Col>Début : <FormControl className="border" type='time' placeholder='Heure de début' onChange={(e) => { setHeureDebut(e.target.value) }}></FormControl></Col>
                  {/* <Col>Fin : <FormControl type='time' placeholder='Heure de fin' onChange={(e) => { setHeureFin(e.target.value) }}></FormControl></Col> */}
                </Row>
                <Row className='mb-2'>
                  <Col>
                    <FormControl className="border" onChange={(e) => setLieuRdv(e.target.value)} placeholder='Saisir le lieu du rendez-vous' ></FormControl></Col>
                </Row >
                <Row className='mb-2'>

                  <Col>
                    <FormSelect onChange={(e) => standLieu(e)} disabled={eventId === '' ? true : false}>
                      <option value="">Choisir un stand</option>
                      {standsChat.map((item, index) =>
                        <option key={index} value={item.id} title={item.description}>{item.description}</option>
                      )}
                    </FormSelect>
                  </Col>

                </Row >

                <div className='d-flex justify-content-end mt-3'>
                  <div className='d-flex justify-content-end mt-3'>

                    <Col>{circular ? <CircularProgress /> : <Button className='btn-recup' disabled={(eventId !== '' && heureDebut !== '' && lieuRdv !== '' && sujetRdv !== '' && standRdv !== '') ? false : true} onClick={() => creationRdv()}>Valider</Button>}</Col>

                    <Button className='btn-recup2 mx-3' variant='secondary' onClick={() => fermerRdv()}>Fermer</Button>
                  </div>

                  <Row>

                  </Row>


                </div>
              </Form >


            }

          </form>
        </Modal.Body>
      </Modal>

      <Row>
        <Navigation
          button={false}
          titre={'Messages'}
          icone={pictos.messagerie_rond_icone}
          photo={monProfil.photo_path}
          retour='dashboard'
          menus={true}

        />

      </Row>

      <Row className='p-0'>
        <Chemin
          breadCrumbs={breadcrumbs_temp}
        />

      </Row>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />

      {loading ?
        <div className='d-flex justify-content-center'>
          <CircularProgress />
        </div>
        :
        <Row className="">
          <Container fluid className="msgListBox" style={{ color: '#271d67', height: '100vh' }}>
            <Row className="">
              <Container>
                <Row className="border-bottom mb-1" style={{ width: '100%', margin: 'auto' }}>
                  <Col sm={"auto"} className="d-flex align-items-center" style={{ width: 'fit-content', paddingRight: '3px', marginRight: '3px' }}>
                    <Avatar src={user_chat.photo !== undefined ? user_chat.photo : ''} />
                  </Col>
                  <Col className="p-0 mx-2" style={{ width: 'fit-content' }}>
                    <Container className="" fluid style={{}}>
                      <Row className="d">
                        <Col sm='auto' className="p-0 py-1">
                          <Row className="text-capitalize">
                            <b style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' }}>{upperFirst(contact)}</b>
                          </Row>
                          <Row className="">
                            <Col className="">{upperFirst(toLower(user_chat.fonction))}</Col>
                          </Row>


                        </Col>

                      </Row>

                    </Container>


                  </Col>
                  <Col sm='auto' className=" p-1  d-flex justify-content-start align-items-center " style={{ width: 'fit-content' }}>

                    <img src={user_chat.from_structure ? null : user_chat.photo_organisation} style={{ width: 'auto', height: '40px' }} alt="" />
                  </Col>
                  <Col className="p-0 px-1  d-flex justify-content-end align-items-center " style={{ width: '' }}>

                    <img className="" onClick={() => ouvrirSupprimer(param.chatid)} src={pictos.poubelle_icone} alt='' style={{ width: '30px', cursor: 'pointer' }} />


                  </Col>

                </Row>
                <Row className="p-3 chat-container" id="container-chat">
                  {messageFilDisc.map((message, id) => {
                    if (message.user.id === user_id) {
                      let laDate1 = new Date(message.createdAt)

                      return <ChatSend key={id} message={message} date={affichageDate(laDate1)} delete={handleDeleteClick} />

                    }
                    else {
                      let laDate2 = new Date(message.createdAt)
                      return <ChatReceive key={id} message={message} date={affichageDate(laDate2)} delete={handleDeleteClick} />
                    }


                  })}
                </Row>
              </Container>

            </Row>

            <Row xs={3} className=" saisie-message d-flex justify-content-center align-items-center py-1 mt-2 border-top" style={{ height: 'auto' }}>


              <IconButton className=" col-sm-auto p-0 btn-create-rdv" title="Créer un rendez-vous" variant="plain" onClick={() => setOpenRdv(true)} sx={{ backgroundColor: 'none', width: 'fit-content' }}>
                {/* <CalendarTodayOutlined title="" style={{ color: '#271d67' }} /> */}
                <img src={pictos.demande_rdv_icone} alt="" width={35} />
              </IconButton>



              <Col xs={9} sm={10} className="d-flex align-items-center areaText" style={{ paddingRight: '10px' }}>


                {/*   
                <Textarea
                  className='px-4 messageInput'
                  onChange={async (e) => msgText(e)}
                  value={message !== '' ? undefined : ''}
                  placeholder="Message"
                  style={{ fontFamily: 'Poppins', borderRadius: '50px', border: '1px solid #271d67', color: '#271d67' }}

                /> */}
                <TextareaAutosize
                  id="messageText"
                  className='px-4 pt-2 messageInput d-flex align-items-center'
                  onChange={async (e) => msgText(e)}
                  // value={message !== '' ? undefined : ''}
                  placeholder="Message"
                  style={{ width: '100%', fontFamily: 'Poppins', borderRadius: '25px', border: '1px solid #271d67', color: '#271d67', resize: 'none' }}

                />

              </Col>

              <Button disabled={message !== '' ? false : true} className="col btn-send-msg p-0 px-1" onClick={handleClick} style={{ borderRadius: '0', background: 'none', border: 'none', color: '#271d67', width: 'fit-content' }}><Send className="" /></Button>


            </Row>
          </Container>
        </Row >
      }

    </Container >
  );
};

export default Chatbox;
