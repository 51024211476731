import React, { useEffect } from 'react';
import '../styles/partenaireExpo.css'
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { pictos } from '../assets/img/pictos';
import Navigation from '../components/Navigation';
import SearchInput from '../components/SearchInput';
import { useState } from 'react';
import { CircularProgress, Menu, MenuItem, Typography } from '@material-ui/core';
import _, { toLower } from 'lodash';
import { upperFirst } from 'lodash';
import { toNormalForm } from './fonctions';
import Breadcrumb from '../components/Breadcrumb';
import { partenaireSelectRedux, partenairesRedux } from '../redux/evenementsServices';
import ErrorPopup from '../components/ErrorPopup';
import Chemin from '../components/Chemin';
import { refreshToken } from '../redux/profilService';
import textConnect from '../textConnect.json'

const PartenairesExpo = () => {
    const listePartenairesInit = useSelector(state => state.evenements.exposants)
    const [listePartenaires, setListePartenaires] = useState(listePartenairesInit)

    const listePartenairesUnique = [...new Map(listePartenairesInit.map(item => [item.domaine, item])).values()].sort(function (a, b) {

        if (upperFirst(a.domaine) < upperFirst(b.domaine)) {

            return -1
        }
        if (upperFirst(a.domaine) > upperFirst(b.domaine)) {
            return 1
        }
        return 0
    })

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])


    const evenementSelected = useSelector(state => state.evenements.evenementSelect)
    const accessToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)
    const monProfil = useSelector(state => state.profil.profil)

    const dispatch = useDispatch()
    const navigationPage = useNavigate()

    const [filtreMot, setFiltreMot] = useState('')

    function filtrePartenaires(e) {
        setFiltreMot(e.target.value)

    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    // function FiltrerExposant(domaine, tableau) {
    //     let resultat
    //     resultat = tableau.filter(item =>
    //         item.domaine === domaine
    //     )

    //     console.log('le resultat : ', resultat)
    //     setListePartenaires(resultat)
    //     setAnchorEl(null)
    // }

    function annulerFiltre() {
        setListePartenaires(listePartenairesInit)
        setAnchorEl(null)
    }

    const [catCheck, setCatCheck] = useState([])

    const handleCheck = (e, value) => {

        if (e.target.checked) {

            setCatCheck((old) => [...old, value])
        }
        else {
            setCatCheck(catCheck.filter((item) => item !== value))
            console.log(catCheck)
        }


    }
    function FiltrerExposant(categorie = [], tableau = []) {

        let resultat = []
        if (categorie.length > 1) {
            console.log('superieur a 1..................')
            console.log(categorie)
            let tabTemp = []


            for (let index = 0; index < categorie.length; index++) {
                // console.log('itemeeee', categorie[index])
                tableau.filter(item =>
                    item.domaine.includes(categorie[index])).map((item2) =>
                        tabTemp.push(item2)
                    )

                resultat = tabTemp
                console.log(resultat)


            }

        }
        else {
            resultat = tableau.filter(item =>
                item.domaine.includes(categorie)
            )
        }


        // console.log('le resultat : ', resultat)
        setListePartenaires(resultat)
        // setAnchorEl(null)
    }

    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })

        FiltrerExposant(catCheck, listePartenairesInit)



    }, [catCheck])

    useEffect(() => {
        setLoading(true)
        partenairesRedux(accessToken, evenementSelected.id).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addExposant',
                    payload: reponse.data
                }
            )
            // navigationPage(`/partenaires`)
            // window.scrollTo(0, 0)
            setLoading(false)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            // participantsList(token, id)
                        })

                }

            }
        })
    }, [])


    const partenaireSelect = async (id, premium) => {
        setLoading(true)
        partenaireSelectRedux(accessToken, evenementSelected.id, id).then((response) => {
            setLoading(false)
            dispatch(
                {
                    type: 'evenements/addExposantSelect',
                    payload: response.data
                }
            );
            navigationPage(`/partenaire/${id}/${premium}`)
            window.scrollTo(0, 0)

        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            // participantsList(token, id)
                        })

                }

            }
        })




    }
    const location = useLocation()

    //Breadcrumbs
    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.nom === 'Accueil' && item.url.split('/')[1] !== 'planStand'))
    breadcrumbs_temp.push({ url: location.pathname, nom: 'Partenaires exposants' })

    const [msgError, setMsgError] = useState('')

    const [openError, setOpenError] = useState(false)
    const [loading, setLoading] = useState(true)

    const closeErrorHandle = () => {
        setOpenError(false)
    }
    return (
        <Container fluid>
            <ErrorPopup
                message={msgError}
                open={openError}
                close={() => closeErrorHandle()}
            />
            <Menu
                className='menu-filtre'
                id="filtre-menu"
                aria-labelledby="btn-filtre"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >

                <Typography className='mx-3 border-bottom' style={{ fontFamily: 'Poppins-bold' }}>{" Filtrer par domaine :"}</Typography>
                {listePartenairesUnique.map((item, index) =>
                    item.domaine === '' ?
                        null
                        :
                        <MenuItem key={index} className='' style={{ fontFamily: 'Poppins' }}>
                            <Col className='mx-1'>
                                <label htmlFor={index}>{upperFirst(toLower(item.domaine))}</label>
                            </Col>
                            <Col className='' sm='auto'>
                                <input id={index} checked={catCheck.filter((cat) => cat === item.domaine).length ? true : false} type="checkbox" name="" onChange={(e) => handleCheck(e, item.domaine)} />
                            </Col>
                        </MenuItem>
                )}
                <MenuItem className='border-top' style={{ fontFamily: 'Poppins' }} onClick={() => annulerFiltre()}>Annuler le filtre</MenuItem>
            </Menu>


            <Row>
                <Navigation
                    button={true}
                    titre={'Partenaires exposants'}
                    icone={pictos.partenaires_rond_icone}
                    evenement={evenementSelected.photo}
                    photo={monProfil.photo_path}
                    retour='dashboard'
                    selected={2}
                    open={(!open).toString()}
                    menus={true}

                />

            </Row>

            <Row className='p-0'>
                <Chemin
                    breadCrumbs={breadcrumbs_temp}
                />

            </Row>

            {loading ?
                <div className='d-flex justify-content-center align-items-center' style={{ height: '30vh' }}>
                    <CircularProgress />
                </div>

                :
                <Row className='container-exposants d-flex justify-content-center'>
                    <Row className='mb-4 d-flex align-items-center justify-content-center'>
                        <SearchInput
                            placeholder={'Rechercher'}
                            filtre={filtrePartenaires}
                        />

                        {/* <Col xl={2} className='' style={{ width: 'fit-content', cursor: 'pointer' }} aria-controls={open ? 'filtre-menu' : undefined} aria-haspopup={true} aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                            <Row className='d-flex justify-content-center' style={{ textAlign: 'center', height: '20px', margin: '' }}>  {'Filtrer'} </Row>
                            <img className='row' src={pictos.filtrer_icone} alt="" style={{ width: '30px', margin: 'auto' }} />
                        </Col> */}
                    </Row >
                    <Row>
                        {listePartenaires.length ?
                            listePartenaires.filter((item) => {
                                return filtreMot.toLowerCase() === '' ? item : (toNormalForm(item.nom.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.domaine.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())))
                            }).length === 0 ?
                                <p className='' style={{ textAlign: 'center', color: '#271d67', fontSize: '120%' }}>Aucun résultat trouvé</p>
                                :
                                listePartenaires.filter((item) => {
                                    return filtreMot.toLowerCase() === '' ? item : (toNormalForm(item.nom.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.domaine.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())))
                                }).map((item, index) =>
                                    <Row key={index} className='partenaire-item d-flex justify-content-start' style={{ paddingTop: '15px' }}>

                                        <Col className='' style={{ textDecoration: 'none', color: '#271d67', cursor: 'pointer' }} onClick={() => partenaireSelect(item.id, 0)}>
                                            <Row>
                                                <Col sm='auto' className='d-flex justify-content-center align-items-center' style={{ width: '90px', height: '80px', marginRight: '10px' }}>
                                                    <img className='' src={item.logo} alt="" style={{ maxWidth: '90px', maxHeight: '80px' }} />
                                                </Col>




                                                {/* <Avatar src={item.logo} sx={{ width: '12vh', height: '12vh' }} /> */}


                                                <Col className='d-flex align-items-center p-0 px-2' style={{ width: 'fit-content' }}>
                                                    <Row className='d-flex'>
                                                        <Row className='' style={{ fontSize: '1rem', wordBreak: 'break-word', fontFamily: 'Poppins-bold' }}><Col> {upperFirst(toLower(item.nom))}</Col></Row>
                                                        <Row className='' style={{ wordBreak: 'break-word' }}><Col>{upperFirst(toLower(item.domaine))}</Col></Row>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {item.stand.code === '' || item.stand.code === undefined ?

                                            null
                                            :
                                            <>
                                                <Col onClick={() => navigationPage('/planStand/' + item.stand.id)} sm='auto' className='p-0 mt-1' style={{ width: 'fit-content', cursor: 'pointer' }}>
                                                    <div className='d-flex align-items-center'>
                                                        <img title={upperFirst(item.stand.code)} src={pictos.lieu_icone} alt="" style={{ height: '30px' }} />
                                                    </div>
                                                </Col>
                                                <Col onClick={() => navigationPage('/planStand/' + item.stand.id)} sm='auto' className='p-0 mt-2' style={{ width: 'fit-content', cursor: 'pointer' }}>
                                                    {upperFirst(item.stand.code.toLowerCase())}
                                                </Col>
                                            </>

                                        }

                                    </Row>
                                )
                            :
                            <></>
                        }

                    </Row>
                </Row>
            }
        </Container>
    );
};

export default PartenairesExpo;