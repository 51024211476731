
import axios from "axios";
import { pictos } from "../assets/img/pictos";
import { URL } from "../redux/api";
import { toLower, upperFirst } from "lodash";



export function categories(cat_img, cat_label, expert, speaker) {
    var tabRetour = [];

    if (cat_img !== '') {
        // console.log('vide')
        tabRetour.push({ icone: cat_img, click: false, title: upperFirst(toLower(cat_label)) });
        if (toLower(cat_label) === toLower('Intervenant') || speaker === true) {
            tabRetour.push({ icone: pictos.speaker_icone, click: false, title: 'Speaker' })

        }
    }

    // if (cat === "Exposant") {
    //     tabRetour.push({ icone: pictos.speaker_icone, click: false })
    // }
    // if (cat === "Congressiste") {
    //     tabRetour.push({ icone: pictos.congressiste_icone, click: false })
    // }
    // if (cat === "Intervenant") {
    //     tabRetour.push({ icone: pictos.intervenant_icone, click: false })
    // }
    // if (cat === "Visiteur") {
    //     tabRetour.push({ icone: pictos.visiteur_icone, click: false })
    // }
    // if (cat === "Visiteur premium") {
    //     tabRetour.push({ icone: pictos.visiteur_icone, click: false })
    // }
    // if (cat === "Visiteur classique") {
    //     tabRetour.push({ icone: pictos.visiteur_icone, click: false })
    // }

    if (expert) {
        tabRetour.push({ icone: pictos.expert_icone, click: false, title: 'Expert' })
    }



    return tabRetour
}


export const deconnexion = (context, navlink) => {
    setTimeout(() => {
        context.setLogged(false)
        localStorage.removeItem("is_logged")
        localStorage.removeItem('tokenAccess')
        localStorage.removeItem('profilUser')
        localStorage.removeItem('persist:root')

        navlink('/')
    }, 1000);

}

export const couleurTheme = (categ) => {
    if (categ.couleur !== '') {
        if (categ.couleur === 'Rouge') {
            return 'red'
        }
        else return categ.couleur
    }

    else if (categ.couleur === '') {
        return '#FF6600'
    }

}

export function pictosCategorie(cat) {
    if (cat.label === '') {
        return pictos.rendez_vous_min_icone
    }


    else {
        return cat.icone
    }


}

export const recupPassword = (e, email) => {
    e.preventDefault()

    console.log('le mail :', email)

    return axios.post(`${URL}/api/password`, {
        email: email
    })


    // circlerec(false)
    // message(false)

}

export const contacterBec = (objet, message, token) => {

    return axios.post(`${URL}/api/contact`, {
        objet: objet,
        message: message
    },
        {
            headers: {
                'Authorization': 'JWT ' + token
            }
        }

    )

}

export const chatSelected = (nom, prenom, fonction, idChat, photoContact, organisationContact, photoOrganisation, structure = true) => {
    return {
        contact: {
            name: `${nom} ${prenom}`,
            fonction: fonction,
            photo: photoContact,
            organisation: organisationContact,
            photo_organisation: structure ? '' : photoOrganisation
        },
        id: idChat,


    };
}
export const MOIS_BIS = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
const MOIS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
export const affichageDate = (date) => {
    let current = new Date(Date.now())
    if (date.getDate() === current.getDate()) {
        return `${date.getHours()}:${date.getMinutes()}`
    }
    if (date.getDate() === current.getDate() - 1) {
        return `Hier, ${date.getHours()}:${date.getMinutes()}`

    }
    if (date.getFullYear() === current.getFullYear()) {
        return `${date.getDate()}/${MOIS[date.getMonth()]}, ${date.getHours()}:${date.getMinutes()}`

    }
    else return `${date.getDate()}/${MOIS[date.getMonth()]} ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`

}

export function toNormalForm(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

