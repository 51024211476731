import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Carousel, Row } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/main.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import { useDispatch, useSelector } from 'react-redux';
import { evenementSelectRedux, evenementsRedux, partenairesPremiumRedux, partenairesRedux } from '../redux/evenementsServices';
import { SOCKETURL } from '../redux/api';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotifNbreContext } from '../Context';
import { NotificationsRedux, pagesContent, refreshToken } from '../redux/profilService';
import Navigation from '../components/Navigation';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import ErrorPopup from '../components/ErrorPopup';
import textConnect from '../textConnect.json'

const Main = () => {
    const Evenements = useSelector(state => state.evenements.evenements)
    const userToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)
    const monProfil = useSelector(state => state.profil.profil)

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])

    let dispatch = useDispatch()

    // console.log(Evenements.length)

    function selectEvt(evenement) {
        evenementSelectRedux(userToken, dispatch, evenement)
        console.log(evenement)
    }


    const navigationPage = useNavigate()
    const texteEvents = useSelector(state => state.profil.contenusTexte.message_choix_event)
    const contenusBO = useSelector(state => state.profil.contenusTexte)

    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')

    const closeErrorHandle = () => {
        setOpenError(false)
    }

    const [loading, setLoading] = useState(true)

    // const texteIntroEvents = useSelector(state => state.profil.contenusTexte.message_introduction_event)

    const selectEvent = (token, id, plan) => {
        setLoading(true)
        evenementSelectRedux(token, id).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addEvenementSelect',
                    payload: reponse.data
                }
            );

            dispatch(
                {
                    type: 'evenements/addPlan',
                    payload: plan
                }
            )
            navigationPage('/dashboard/' + id)
            setLoading(false)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')
                            selectEvent(token, id)
                        })
                }
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }

            }
        })

    }

    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: [{ url: '/', nom: 'Accueil' }]
        })
        dispatch(
            {
                type: 'evenements/addEvenementSelect',
                payload: ''
            }
        );
        pagesContent().then((reponse) => {
            dispatch({
                type: 'Profil/addTextContent',
                payload: reponse.data

            })
            evenementsRedux(userToken).then((response) => {
                dispatch(
                    {
                        type: 'evenements/addEvenements',
                        payload: response.data
                    }
                );
                setLoading(false)
            }).catch((erreur) => {
                setLoading(false)
                if (!netStatus) {
                    setMsgError(textConnect.internet)
                    setOpenError(true)
                }
                if (erreur.response) {

                    if (erreur.response.status !== 401) {
                        setMsgError(textConnect.error)
                        setOpenError(true)
                    }

                }
            })

        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                        })
                }
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }

            }
        })


    }, [])
    return (
        <Container fluid>

            <ErrorPopup
                message={msgError}
                open={openError}
                close={() => closeErrorHandle()}
            />

            <>
                <Row>
                    <Navigation
                        button={false}
                        photo={monProfil.photo_path}
                        retour=''
                        titre={<div dangerouslySetInnerHTML={{
                            __html: contenusBO.message_introduction_event
                        }} />}
                        menus={true}
                    />
                </Row>
                {loading ?
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '30vh' }}>
                        <CircularProgress />
                    </div>

                    :

                    <>
                        <Row className='texte-choix-evt mt-3'>
                            <p>Choisissez votre événement</p>
                        </Row>
                        <Row className='' style={{ textAlign: 'center', width: '80%', margin: 'auto' }}>
                            <div dangerouslySetInnerHTML={{
                                __html: texteEvents
                            }} />
                        </Row>

                        <Row className='evenements' xs={Evenements.length}>


                            {Evenements.length ? Evenements.map((item) =>
                                <Col key={item.id} sm={''} className='evenement mb-3'>
                                    <NavLink onClick={() => { selectEvent(userToken, item.id, item.plan) }}>
                                        <img src={item.photo} alt="Evenement" />
                                    </NavLink>
                                </Col>
                            ) : <></>}



                        </Row>
                    </>
                }
            </>


        </Container>

    );
};

export default Main;