import axios from "axios";
import { URL } from "./api";

export const evenementsRedux = (token) => {
  return axios.get(URL + "/v2/api/evenements", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const programmesRedux = (token, id_evenement) => {
  return axios.get(`${URL}/v2/api/evenements/${id_evenement}/programmes`, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};

export const partenairesRedux = (token, id_evenement) => {
  return axios.get(URL + "/v3/api/evenement/" + id_evenement + "/exposant", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const partenaireSelectRedux = (token, id_evenement, id_partenaire) => {
  return axios.get(
    `${URL}/api/evenement/${id_evenement}/exposant/${id_partenaire}`,
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};
export const partenairesPremiumRedux = (token, id_evenement) => {
  return axios.get(
    URL + "/v3/api/evenement/" + id_evenement + "/exposant?type=premium",
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};

export const evenementSelectRedux = (token, id) => {
  return axios.get(URL + "/v2/api/evenements/" + id, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
// export const participantsRedux = (token, id) => {
//     return axios.get(URL + "/api/evenements/" + id + "/participants", {
//         headers: {
//             'Authorization': 'JWT ' + token
//         }
//     })
// }
export const participantsCategories = (token, id) => {
  return axios.get(`${URL}/api/profil/${id}`, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const participantsRedux = (token, id) => {
  return axios.get(`${URL}/v3/api/evenements/${id}/participants`, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};

export const participantsReduxPage = async (
  token,
  id_evenement,
  page,
  filtre,
  expert = false,
  controller = null,
  mot
) => {
  if (filtre === "" && expert !== false) {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants?filter=expert&page=${page}&search=${mot}`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }
  if (filtre !== "" && expert === false) {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants?filter=${filtre}&page=${page}&search=${mot}`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }
  if (filtre !== "" && expert !== false) {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants?filter=${filtre}-expert&page=${page}&search=${mot}`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  } else {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants?page=${page}&search=${mot}`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }
};
// export const participantsReduxPage = async (token, url) => {
//     return await axios.get(`${url}`, {
//         headers: {
//             'Authorization': 'JWT ' + token
//         }
//     })
// }
export const participantSearch = async (token, id_evenement, mot) => {
  return axios.get(
    `${URL}/v3/api/evenements/${id_evenement}/participants?search=${mot}`,
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};
export const cancelParticipantsRedux = async (
  token,
  id_evenement,
  mot,
  controller
) => {
  // axios.get('/user/12345', {
  //   cancelToken:
  // }).catch(function (thrown) {
  //   if (axios.isCancel(thrown)) {
  //     console.log('Request canceled', thrown.message);
  //   } else {
  //     // handle error
  //   }
  // });
  return axios.get(
    `${URL}/v3/api/evenements/${id_evenement}/participants?search=${mot}`,
    {
      signal: controller.signal,
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};

export const participantsFiltre = async (
  token,
  id_evenement,
  filtre,
  expert = false,
  controller = null
) => {
  if (filtre === "" && expert !== false) {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants?filter=expert`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }

  if (filtre !== "" && expert === false) {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants?filter=${filtre}`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }
  if (filtre !== "" && expert !== false) {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants?filter=${filtre}-expert`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }
  if (filtre === "" && expert === false) {
    return await axios.get(
      `${URL}/v3/api/evenements/${id_evenement}/participants`,
      {
        signal: controller.signal,
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }
};

export const participantSelectRedux = (
  token,
  idParticipant,
  id_evenement = ""
) => {
  if (id_evenement === "") {
    return axios.get(URL + `/api/participants/${idParticipant}`, {
      headers: {
        Authorization: "JWT " + token,
      },
    });
  } else {
    return axios.get(
      URL + `/api/participants/${idParticipant}/${id_evenement}`,
      {
        headers: {
          Authorization: "JWT " + token,
        },
      }
    );
  }
};
export const participantEvenementSelectRedux = (
  token,
  idParticipant,
  id_evenement
) => {
  return axios.get(
    URL + "/api/evenements/" + id_evenement + "/participants/" + idParticipant,
    {
      headers: {
        Authorization: "JWT " + token,
      },
    }
  );
};
export const programmeSelectRedux = (token, id) => {
  return axios.get(URL + "/api/programmes/" + id, {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
export const maJourneeRedux = (token, idEvenement) => {
  return axios.get(URL + "/api/participants/" + idEvenement + "/programmes", {
    headers: {
      Authorization: "JWT " + token,
    },
  });
};
