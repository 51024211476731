import { Snackbar } from '@material-ui/core';
import { Alert } from '@mui/material';
import React from 'react';

const ErrorPopup = (props) => {
    return (
        <Snackbar

            open={props.open}
            onClose={props.close}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            autoHideDuration={3000}
        >
            <Alert severity="error" style={{ zIndex: '1000' }}>
                {props.message}
            </Alert>
        </Snackbar>
    );
};

export default ErrorPopup;