import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    refresh: '',
    access: '',
    idUser: 'default',
    souvenir: false,
    erreur: '',
    parametres: {}
}


const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addRefresh: (state, action) => {
            state.refresh = action.payload

        },
        addAccess: (state, action) => {
            state.access = action.payload
        },
        addId: (state, action) => {
            state.idUser = action.payload
        },
        addErreur: (state, action) => {
            state.erreur = action.payload
        },
        addSouvenir: (state, action) => {
            state.souvenir = action.payload
        },
        addParametres: (state, action) => {
            state.parametres = action.payload
        },
        resetUser: () => initialState
    },

})


export const { addRefresh, addAccess, addId, addErreur, addSouvenir, addParametres, resetUser } = authSlice.actions;
export default authSlice.reducer;