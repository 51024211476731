import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./redux/authSlice";
import profilSlice from "./redux/profilSlice"
import circleSlice from "./redux/circleSlice";
import storage from "redux-persist/lib/storage"
import { persistReducer } from "redux-persist"
import evenementsSlice from "./redux/evenementsSlice";

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
}
const reducer = combineReducers({
    user: authSlice,
    profil: profilSlice,
    // circleBar: circleSlice,
    evenements: evenementsSlice
})

const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({

    reducer: persistedReducer,

})

export default store