import axios from "axios";
import { URL } from "./api";


export const UpdateProfil = async (valeur, id_participant, token) => {
    return await axios.post(URL + '/api/participants/' + id_participant + '/update', valeur,
        {
            headers: {
                'Authorization': 'JWT ' + token,
            }
        }
    )
}


export const UpdateNotification = async (id_notification, token) => {
    return await axios.patch(URL + '/api/notifications/' + id_notification, {
        etat: true
    },
        {
            headers: {
                'Authorization': 'JWT ' + token,
            }
        }
    )

}

