import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../components/Breadcrumb';
import { NavLink, useLocation } from 'react-router-dom';
import { CircularProgress, FormControlLabel, LinearProgress, styled } from '@material-ui/core';
import Switch from '@mui/material/Switch';
import '../styles/parametres.css'
import { getParametres, setParametres } from '../redux/authService';
import ErrorPopup from '../components/ErrorPopup';
import { useState } from 'react';
import Chemin from '../components/Chemin';
import { refreshToken } from '../redux/profilService';
import textConnect from '../textConnect.json'

const Parametres = () => {
    const monProfil = useSelector(state => state.profil.profil)
    const accessToken = useSelector((state) => state.user.access);
    const refresh_token = useSelector(state => state.user.refresh)

    const mesParametres = useSelector(state => state.user.parametres)
    const location = useLocation()
    const dispatch = useDispatch()
    const [loadParam, setLoadParam] = useState(false)

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])

    const parametrer = (parametre) => {
        setLoadParam(true)
        setParametres(accessToken, parametre).then((response) => {
            // console.log('paramètre changé')            
            getParametres(accessToken).then((reponse) => {
                dispatch(
                    {
                        type: 'user/addParametres',
                        payload: reponse.data
                    }
                )
                setLoadParam(false)
            })
        }).catch((erreur) => {
            setLoadParam(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            parametrer(parametre)
                        })

                }

            }

        })
    }

    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')


    const [loading, setLoading] = useState(true)

    const closeErrorHandle = () => {
        setOpenError(false)
    }

    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })
        getParametres(accessToken).then((response) => {
            dispatch({
                type: 'user/addParametres',
                payload: response.data
            })

            setLoading(false)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            // participantsList(token, id)

                        })

                }
            }
        })
    }, [])
    //Breadcrumbs
    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.nom === 'Accueil'))
    breadcrumbs_temp.push({ url: location.pathname, nom: 'Paramètres' })

    const BlueSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'red',
            '&:hover': {
                backgroundColor: 'gba(#271d67, 0.8)',
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#271d67',
        },
    }));

    return (
        <Container fluid>
            <ErrorPopup
                message={msgError}
                open={openError}
                close={() => closeErrorHandle()}
            />

            <Row>
                <Navigation
                    button={false}
                    titre={'Mes paramètres'}
                    photo={monProfil.photo_path}
                    retour='dashboard'
                    menus={true}

                />
            </Row>
            {loadParam ?

                <Row className='' style={{ marginTop: '-7px' }}>
                    <LinearProgress />
                </Row>
                :
                null

            }

            <Row className='p-0'>
                <Chemin
                    breadCrumbs={breadcrumbs_temp}
                />

            </Row>
            {/* <h1>Mes Paramètres</h1> */}

            {loading ?
                <div className='d-flex justify-content-center'>
                    <CircularProgress />
                    {/* <LinearProgress /> */}
                </div>
                :
                <>
                    <Row className='parametre-container mt-4'>
                        {
                            mesParametres.length === 0 || mesParametres.length === undefined ?

                                null

                                :

                                mesParametres.map((item, index) => {
                                    return (
                                        <Container key={index}>
                                            <Row className='d-flex justify-content-center border-bottom p-0' style={{ width: '' }}>
                                                <Col xs={9} className='d-flex align-items-center p-0'>
                                                    {mesParametres[index].text}
                                                </Col>
                                                <Col className='d-flex justify-content-end p-0' style={{ width: 'fit-content' }}>
                                                    <BlueSwitch disabled={loadParam ? true : false} checked={mesParametres[index].etat === true ? true : false} style={{ color: mesParametres[index].etat === true ? '#271d67' : '#f0f1f1' }} onChange={(e) => parametrer(mesParametres[index].parametre)} />
                                                </Col>
                                            </Row>
                                        </Container>

                                    )

                                })
                        }

                    </Row>
                    {mesParametres.length ?
                        <Row className='parametre-container mt-1'>
                            <p className='p-0'>Pour clôturer mon compte - <NavLink to='/suppressionCompte'>cliquer ici</NavLink></p>
                        </Row>
                        :
                        null
                    }

                </>


            }

        </Container >
    );
};

export default Parametres;