import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../styles/dashboard.css'
import { pictos } from '../assets/img/pictos';
import { useDispatch, useSelector } from 'react-redux';
import { evenementSelectRedux, maJourneeRedux, partenaireSelectRedux, partenairesPremiumRedux, partenairesRedux, participantsRedux, programmesRedux } from '../redux/evenementsServices';
import { NotificationsRedux, contactsRedux, demandesRedux, invitationsRedux, refreshToken } from '../redux/profilService';
import { SOCKETURL } from '../redux/api';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { NotifNbreContext, BreadcrumbContext } from '../Context';
import Navigation from '../components/Navigation';
import _ from 'lodash'
import ErrorPopup from '../components/ErrorPopup';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import { MOIS_BIS } from './fonctions';
import { upperFirst } from 'lodash';
import textConnect from '../textConnect.json'



const Dashboard = () => {
    const monProfil = useSelector(state => state.profil.profil)
    const userToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)
    const evenementSelected = useSelector(state => state.evenements.evenementSelect)
    const listPremium = useSelector(state => state.evenements.exposantsPremium)
    const listExposants = useSelector(state => state.evenements.exposantsPremium)
    const [listCatExposants, setListCatExposants] = useState([])

    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')


    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loadingItem, setLoadingItem] = useState('')

    const closeErrorHandle = () => {
        setOpenError(false)
    }



    const dispatch = useDispatch()
    const navigationPage = useNavigate()

    const nbreNotif = useContext(NotifNbreContext)
    //Breadcrumbs
    const breadCrumbs = useContext(BreadcrumbContext)

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])

    const { readyState, sendJsonMessage } = useWebSocket(
        `${SOCKETURL}/notification/?token=${userToken}`,

        {
            onMessage: (e) => {

                // console.log("nouvelle notification : ")
                NotificationsRedux(userToken, dispatch, nbreNotif, monProfil.id)

            },
        }
    );


    const groupBy = (data, key) => {
        return data.reduce(function (acc, item) {
            (acc[item[key]] = acc[item[key]] || []).push(item);
            return acc;
        }, {});
    };

    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: [{ url: location.pathname, nom: 'Accueil' }]
        })
        setListCatExposants(Object.entries(groupBy(listExposants, 'type')))
    }, [listExposants])


    const partenaireSelect = async (id, premium) => {
        setLoading2(true)
        partenaireSelectRedux(userToken, evenementSelected.id, id).then((response) => {
            setLoading2(false)
            dispatch(
                {
                    type: 'evenements/addExposantSelect',
                    payload: response.data
                }
            );
            navigationPage(`/partenaire/${id}/${premium}`)
            window.scrollTo(0, 0)
        }).catch((erreur) => {
            setLoading2(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            // participantsList(token, id)
                        })

                }

            }
        })


    }
    const partenaires = () => {
        navigationPage('/partenaires')
        window.scrollTo(0, 0)
    }
    const programmes = () => {
        navigationPage('/programme')
        window.scrollTo(0, 0)
    }

    const participantsList = (token, id) => {
        setLoadingItem('participants')
        setLoading(true)

        participantsRedux(token, id).then((reponse) => {
            console.log(reponse)
            dispatch(
                {
                    type: 'evenements/addParticipants',
                    payload: reponse.data
                }
            );
            navigationPage('/participants')
            setLoading(false)
            window.scrollTo(0, 0)

            // contactsRedux(userToken).then((reponse) => {
            //     // console.log(reponse.data)
            //     setLoading(false)
            //     dispatch(
            //         {
            //             type: 'Profil/addContacts',
            //             payload: reponse.data
            //         }
            //     );

            //     demandesRedux(userToken).then((reponse) => {
            //         // console.log(reponse.data)

            //         dispatch(
            //             {
            //                 type: 'Profil/addDemandes',
            //                 payload: reponse.data
            //             }
            //         );

            //     })

            //     invitationsRedux(userToken).then((reponse) => {
            //         // console.log(reponse.data)

            //         dispatch(
            //             {
            //                 type: 'Profil/addInvitations',
            //                 payload: reponse.data
            //             }
            //         );

            //     })

            //     // navigationPage('/contacts')


            // })

        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            participantsList(token, id)
                        })

                }
            }
        })
    }
    const partenairesList = (token, id_event) => {
        setLoadingItem('partenaires')
        setLoading(true)
        partenairesRedux(token, id_event).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addExposant',
                    payload: reponse.data
                }
            )
            navigationPage(`/partenaires`)
            window.scrollTo(0, 0)
            setLoading(false)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
            }
            if (erreur.response.status === 401) {
                refreshToken(refresh_token).then(
                    async (reponse) => {
                        dispatch(
                            {
                                type: 'user/addRefresh',
                                payload: reponse.data.refresh
                            }
                        );
                        dispatch(
                            {
                                type: 'user/addAccess',
                                payload: reponse.data.access
                            }
                        )
                        console.log('token refresh...')
                        partenairesList(token, id_event)

                    })



            }
        })


    }
    const maJournee = (token, id) => {
        setLoadingItem('journee')
        setLoading(true)
        maJourneeRedux(token, id).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addMajournee',
                    payload: reponse.data
                }
            );
            // console.log('Get ma Journée :', reponse)
            navigationPage('/majournee')
            setLoading(false)
            window.scrollTo(0, 0)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')
                            maJournee(token, id)

                        })



                }
            }
        })
    }

    const param = useParams()

    const mesProgrammes = (token, idEvent) => {
        setLoadingItem('programmes')
        setLoading(true)
        programmesRedux(token, idEvent).then(async (response) => {
            dispatch(
                {
                    type: 'evenements/addProgramme',
                    payload: response.data
                }
            );
            navigationPage('/programmes')
            setLoading(false)
            window.scrollTo(0, 0)

        }).catch((erreur) => {



            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    console.log('iuhiuerhfiuerh')
                    console.log(erreur.response)
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')
                            mesProgrammes(token, idEvent)

                        })



                }
            }
        })

        // evenementSelectRedux(token, idEvent).then(async (response) => {
        //     dispatch(
        //         {
        //             type: 'evenements/addEvenementSelect',
        //             payload: response.data
        //         }
        //     );
        //     navigationPage('/programmes')
        //     setLoading(false)
        //     window.scrollTo(0, 0)

        // })

    }

    const location = useLocation()

    useEffect(() => {

        setLoading2(true)

        partenairesPremiumRedux(userToken, evenementSelected.id).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addExposantPremium',
                    payload: reponse.data
                }
            )

            // navigationPage('/dashboard/' + id)
            setLoading2(false)
        }).catch((erreur) => {
            setLoading2(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {

                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                        })



                }

            }
        })


    }, [])

    function container() {
        const eventDate = new Date(evenementSelected.date)

        return (
            <Container fluid className='dashboardMenu '>
                {/* <Button onClick={() => console.log(location)}>Location</Button> */}
                <ErrorPopup
                    message={msgError}
                    open={openError}
                    close={() => closeErrorHandle()}
                />
                <Row className='dashboardTitle'>
                    {/* <p>Votre tableau de bord</p> */}
                    <p>{evenementSelected.nom}</p>
                </Row>
                <Row className='' style={{ color: 'white', fontSize: '1rem', fontWeight: '0', fontFamily: 'Poppins-light', textAlign: 'center' }}> <br />

                    <p>{`${eventDate.getUTCDate()} ${MOIS_BIS[eventDate.getMonth()]} ${eventDate.getFullYear()}, ${evenementSelected.lieu_evenement}`}</p><br />

                </Row>
                <Row>
                    <Row sm={5} className=' menu-container d-flex justify-content-center'>
                        <Col className='d-flex justify-content-center '>
                            <Row className='program-container dashboard-item d-flex justify-content-center'>
                                <div style={{ cursor: 'pointer' }} onClick={() => mesProgrammes(userToken, param.idevent)}>
                                    <Row>
                                        <img src={pictos.programme_icone} alt="" />
                                    </Row>
                                    <Row className=' d-flex align-items-end'>
                                        {loading ? loadingItem === 'programmes' ? <LinearProgress /> : <></> : <></>}
                                        <p>Programme</p>
                                    </Row>
                                </div>
                            </Row>
                        </Col>
                        <Col className=' d-flex justify-content-center'>
                            <Row className='partenaire-container dashboard-item d-flex justify-content-center' style={{}}>
                                <div style={{ cursor: 'pointer' }} onClick={() => { partenairesList(userToken, evenementSelected.id) }}>
                                    <Row>
                                        <img src={pictos.partenaires_icone} alt="" />
                                    </Row>
                                    <Row>
                                        {loading ? loadingItem === 'partenaires' ? <LinearProgress /> : <></> : <></>}
                                        <p>Partenaires exposants</p>
                                    </Row>
                                </div>

                            </Row>
                        </Col>
                        <Col className='d-flex justify-content-center p'>
                            <Row className='journee-container dashboard-item d-flex justify-content-center' style={{}}>
                                <NavLink onClick={() => maJournee(userToken, evenementSelected.id)}>
                                    <Row>
                                        <img src={pictos.journee_icone} alt="" />
                                    </Row>
                                    <Row>
                                        {loading ? loadingItem === 'journee' ? <LinearProgress /> : <></> : <></>}
                                        <p>Ma journée</p>
                                    </Row>
                                </NavLink>

                            </Row>
                        </Col>
                        <Col className='d-flex justify-content-center p'>
                            <Row className='participant-container dashboard-item d-flex justify-content-center' style={{}}>
                                <NavLink onClick={() => participantsList(userToken, evenementSelected.id)}>
                                    <Row>
                                        <img src={pictos.participant_icone} alt="" />
                                    </Row>
                                    <Row>
                                        {loading ? loadingItem === 'participants' ? <LinearProgress /> : <></> : <></>}
                                        <p>Participants</p>
                                    </Row>
                                </NavLink>

                            </Row>
                        </Col>
                        <Col className='d-flex justify-content-center p'>
                            <Row className='stand-container dashboard-item d-flex justify-content-center' style={{}}>
                                <div style={{ cursor: 'pointer' }} onClick={() => navigationPage("/planStand/0")}>
                                    <Row>
                                        <img src={pictos.plan_stand_icone} alt="" />
                                    </Row>
                                    <Row>
                                        <p>Plan des stands</p>
                                    </Row>
                                </div>

                            </Row>
                        </Col>
                    </Row>
                </Row>
            </Container>
        )

    }

    return (

        <Container fluid>

            <Row>
                <Navigation
                    button={false}
                    evenement={evenementSelected.photo}
                    photo={monProfil.photo_path}
                    retour='\'
                    content={container()}
                    menus={true}

                />
                {/* <NavbarDashboard content={container()} evenement={evenementSelected.photo} photo={monProfil.photo_path} /> */}
            </Row>

            <Row className='premium-list d-flex justify-content-center mt-4' >
                {loading2 ?
                    <div className='d-flex justify-content-center align-items-center my-2' style={{ height: '' }}>
                        <CircularProgress />
                    </div>
                    :
                    listCatExposants.map((item, index) =>
                        <div className='mb-3' key={index} style={{ margin: 'auto' }}>
                            <Row className='dashboardTitle mb-2  p-0' style={{ color: '#271d67', fontSize: '1.3rem', textAlign: 'center' }}>
                                <div>{upperFirst(item[0].toLowerCase())}</div>
                            </Row>
                            <Row className='d-flex justify-content-center align-items-center' style={{}} xs={item.length ? item.length : ''}>
                                {item[1].map((item, index) =>
                                    <Col key={index} className='d-flex align-items-center d-flex justify-content-center m-3' style={{ width: '120px', height: '70px', cursor: 'pointer', }} >
                                        <img onClick={() => { partenaireSelect(item.id, 1) }} className='' src={item.logo} alt={item.nom} title={item.nom} style={{ maxWidth: '120px', maxHeight: '70px' }} />
                                    </Col>
                                )}
                            </Row>
                        </div>
                    )
                }
                {/* {listExposants.length ?
                    listExposants.map((item, index) =>
                        <img key={index} className='col-sm-auto m-2' style={{ width: 'auto', height: '60px' }} src={item.logo} alt={item.nom} />
                    )
                    :
                    <></>
                } */}
            </Row>


        </Container>
    );
};

export default Dashboard;