import axios from "axios";
import React, { useContext } from "react";
import jwt_decode from "jwt-decode";
import { URL } from "./api";
import { NotificationsRedux, profilRedux } from "./profilService";
import { evenementsRedux } from "./evenementsServices";
import { NotifContext } from "../Context";

// const notifcontext=useContext(NotifContext)
export const connexionRedux = async (email, password) => {

    return axios.post(URL + '/token', {
        email: email,
        password: password
    })

    // return resultat



}

export const getParametres = (token) => {
    return axios.get(`${URL}/api/user-parameters`, {
        headers: {
            'Authorization': 'JWT ' + token
        }
    })
}
export const setParametres = (token, param) => {
    return axios.post(`${URL}/api/user-parameters`, {
        parametre: param
    }, {
        headers: {
            'Authorization': 'JWT ' + token
        }
    })
}