import React, { useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { Avatar, Badge, CircularProgress } from '@material-ui/core';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { pictos } from '../assets/img/pictos';
import { lowerCase, toLower, upperFirst } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import '../styles/compte.css'
import _ from 'lodash';
import Navigation from '../components/Navigation';
import { categories, chatSelected, couleurTheme, toNormalForm } from './fonctions';
import { chatSelect, contactsRedux, demandesRedux, envoyerDemandeRedux, invitationsRedux, refreshToken } from '../redux/profilService';
import { partenaireSelectRedux, partenairesRedux, participantSelectRedux, programmeSelectRedux } from '../redux/evenementsServices';
import ErrorPopup from '../components/ErrorPopup';
import Chemin from '../components/Chemin';
import { useEffect } from 'react';
import { styled } from '@mui/material';
import textConnect from '../textConnect.json'

const CompteParticipant = () => {
    const param = useParams()

    const profilParticipant = useSelector(state => state.evenements.participantSelect)
    const listeParticipants = useSelector(state => state.evenements.participants.results)
    // const categorieParticipant = param.contact === '0' ? listeParticipants.length ? listeParticipants.filter((item) => { return item.id === profilParticipant.id })[0].profil : '' : ''
    const categorieParticipant = param.contact === '0' ? profilParticipant.profil === undefined ? '' : profilParticipant.profil : ''
    const evenementSelected = useSelector(state => state.evenements.evenementSelect)

    const monProfil = useSelector(state => state.profil.profil)
    const accessToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)

    // const mesContacts = useSelector(state => state.profil.contacts)

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])

    function reseauSociaux(reseaux) {
        var tabRetour = [];

        for (let index = 0; index < reseaux.length; index++) {

            if (reseaux[index].nom === "link") {
                tabRetour.push({ logo: pictos.linkedin_icone, url: reseaux[index].url })
            }
            if (reseaux[index].nom === "twi") {
                tabRetour.push({ logo: pictos.twitter_icone, url: reseaux[index].url })
            }
        }

        return tabRetour
    }

    const [chat, setChat] = useState({})
    const [contactChat, setContactChat] = useState([])

    const dispatch = useDispatch()
    const navigationPage = useNavigate()

    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')

    const handleEnvoiDemande = () => {
        setCircle2(true)
        envoyerDemandeRedux(accessToken, selectProfil, evenementSelected.id).then((reponse) => {
            // console.log('demande envoyé :', reponse.data)
            setPending(true)
            setCircle2(false)
            setOpenEnvoyerDemande(false)

            // participantsRedux(accessToken, dispatch, evenementSelected.id)


        }).catch((erreur) => {
            setCircle2(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );

                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )

                            console.log('token refresh...')
                            handleEnvoiDemande()

                        })
                }
            }
        })
    }
    const closeErrorHandle = () => {
        setOpenError(false)
    }
    const [circle, setCircle] = useState(false)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)

    const programSelect = (token, id) => {
        setLoading(true)
        programmeSelectRedux(token, id).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addProgrammeSelect',
                    payload: reponse.data
                }
            );
            // window.open('/programmeDetail', '_top')
            navigationPage('/programme/' + id + '/0')
            setLoading(false)
            window.scrollTo(0, 0)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );

                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )

                            console.log('token refresh...')
                            programSelect(token, id)

                        })
                }
            }
        })
    }
    const partenaireSelect = async (id) => {
        setLoading(true)

        // partenairesRedux(accessToken, evenementSelected.id).then((reponse) => {
        //     // console.log(reponse.data.filter(item => item.id_organisation === id))
        //     if (reponse.data.filter(item => item.id_organisation === id).length === 1) {
        partenaireSelectRedux(accessToken, evenementSelected.id, id).then((response) => {

            dispatch(
                {
                    type: 'evenements/addExposantSelect',
                    payload: response.data
                }
            );
            navigationPage(`/partenaire/${id}/0`)
            window.scrollTo(0, 0)
            setLoading(false)

        })

            // }
            //     else {
            //         setMsgError("L'organisation ne participe pas dans cet événement")
            //         setOpenError(true)
            //         setLoading(false)
            //     }
            // })
            .catch((erreur) => {
                setLoading(false)
                if (!netStatus) {
                    setMsgError(textConnect.internet)
                    setOpenError(true)
                }
                if (erreur.response) {
                    if (erreur.response.status !== 401) {
                        setMsgError(textConnect.error)
                        setOpenError(true)
                    }
                    if (erreur.response.status === 401) {
                        refreshToken(refresh_token).then(
                            async (reponse) => {
                                dispatch(
                                    {
                                        type: 'user/addRefresh',
                                        payload: reponse.data.refresh
                                    }
                                );

                                dispatch(
                                    {
                                        type: 'user/addAccess',
                                        payload: reponse.data.access
                                    }
                                )

                                console.log('token refresh...')
                                partenaireSelect(id)

                            })
                    }
                }
            })

        // dispatch(
        //     {
        //         type: 'evenements/addExposantSelect',
        //         payload: contenu
        //     }
        // );

        // navigationPage(`/partenaire/${id}`)
        // window.scrollTo(0, 0)

    }
    const location = useLocation()
    //Breadcrumbs
    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.url.split('/')[1] !== 'CompteParticipant' && item.url.split('/')[1] !== 'planStand'))
    breadcrumbs_temp.push({ url: location.pathname, nom: `${upperFirst(profilParticipant.user.prenom)} ${upperFirst(profilParticipant.user.nom)}` })

    useEffect(() => {
        participantSelectRedux(accessToken, profilParticipant.id, evenementSelected.id).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addParticipantSelect',
                    payload: reponse.data
                }
            );
            setLoading(false)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );

                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )

                            console.log('token refresh...')

                        })
                }
            }
        })

    }, [])


    useEffect(() => {



        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })
        contactsRedux(accessToken).then((response) => {
            setContactChat(response.data.filter((item) => { return item.id === profilParticipant.id }))
            setChat(chatSelected(profilParticipant.user.nom, profilParticipant.user.prenom, profilParticipant.fonction, profilParticipant.conversation.id, profilParticipant.photo_path, profilParticipant.organisation.nom, profilParticipant.organisation.logo, profilParticipant.from_structure))
            // setLoading(false)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );

                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )

                            console.log('token refresh...')

                        })
                }
            }
        })



    }, [])
    const [pending, setPending] = useState(false)
    const [openEnvoyerDemande, setOpenEnvoyerDemande] = useState(false)
    const [selectProfil, setSelectProfil] = useState('')
    const [circle2, setCircle2] = useState(false)
    const ouvrirEnvoyer = (id) => {
        setSelectProfil(id)
        setOpenEnvoyerDemande(true)
    }

    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 19,
        height: 19,
        left: -5
        // border: `2px solid ${theme.palette.background.paper}`,
    }));

    return (
        <div>
            <Container fluid>
                <Modal className='d-flex align-items-center' show={openEnvoyerDemande} onHide={() => setOpenEnvoyerDemande(false)} style={{ fontFamily: 'Poppins' }}>
                    <Modal.Header closeVariant='white' closeButton style={{ color: 'white', backgroundColor: '#271d67' }}>
                        <h4>
                            Confirmation
                        </h4>
                    </Modal.Header>
                    <form>
                        <Modal.Body>

                            Voulez-vous vraiment envoyer une demande ?


                            <div className='d-flex justify-content-end mt-3'>
                                {circle2 ?
                                    <CircularProgress />
                                    :

                                    <Button className='btn-recup' onClick={() => handleEnvoiDemande()}>Confirmer</Button>
                                }

                                <Button className='btn-recup2 mx-2' variant='secondary' onClick={() => setOpenEnvoyerDemande(false)}>Fermer</Button>
                            </div>

                        </Modal.Body>
                    </form>
                </Modal>

                <ErrorPopup
                    message={msgError}
                    open={openError}
                    close={() => closeErrorHandle()}
                />
                <Row>
                    <Navigation
                        button={param.contact === '0' ? true : false}
                        titre={param.contact === '0' ? 'Participants' : 'Mes contacts'}
                        photo={monProfil.photo_path}
                        icone={param.contact === '0' ? pictos.participant_rond_icone : pictos.contact_rond_icone}
                        evenement={param.contact === '0' ? evenementSelected.photo : ''}
                        retour='dashboard'
                        profile={false}
                        selected={4}
                        open={(!open).toString()}
                        menus={true}
                    />

                </Row>
                {/* <Row className='p-0'>
                    <Breadcrumb
                        third={true}
                        focus={param.contact === '0' ? 'Participants' : 'Mes contacts'}
                        location={param.contact === '0' ? '/participants' : '/contacts'}
                        subfocus={`${upperFirst(profilParticipant.user.prenom)} ${upperFirst(profilParticipant.user.nom)}`}
                        sublocation={location}
                    />
                </Row> */}
                <Row className='p-0'>
                    <Chemin
                        breadCrumbs={breadCrumbs}
                    />
                </Row>
                {loading ?
                    <div className='d-flex justify-content-center'>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        <Row style={{}} className='infos-profil'>
                            <div className='container-gauche'>
                                <Container>
                                    <Row className='' style={{ marginBottom: '6px' }}>
                                        <Col xs={6} style={{ width: '', marginRight: '50px' }} className='p-0'>
                                            <Badge style={{ margin: 'auto', backgroundColor: '', width: '120px' }}
                                                overlap='circular'
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                badgeContent={param.contact === '0' ? [
                                                    categories(categorieParticipant.photo, categorieParticipant.description, profilParticipant.is_expert, profilParticipant.is_speaker).map((item, index) =>
                                                        <SmallAvatar key={index} title={item.title} className='p-0' src={item.icone} alt="" style={{ width: '25px', height: '25px', backgroundColor: '  white' }} />
                                                    )]
                                                    :
                                                    0
                                                }

                                            >

                                                <Avatar className='avatar-profil' src={profilParticipant.photo_path} style={{ width: '100px', height: '100px', backgroundColor: '' }} />
                                                {/* <img src={profilParticipant.photo} alt="" style={{ width: '100px', height: '100px', backgroundColor: '' }} /> */}
                                                {/* <Avatar onClick={handleShowPhoto} src={PROFIL_TEST} style={{ width: '100px', height: '100px', backgroundColor: '' }} /> */}



                                            </Badge>
                                        </Col>

                                        {profilParticipant.is_contact_status === 'pending' || pending === true ?
                                            <Col className='p-0 d-flex align-items-center justify-content-end' style={{ width: '' }}>
                                                <img src={pictos.demande_attente_icone} alt="" width={'35px'} />
                                            </Col>
                                            :
                                            profilParticipant.is_contact_status === 'invite' ?
                                                <Col className='p-0 d-flex align-items-center justify-content-end' style={{ width: '' }}>
                                                    <img onClick={() => ouvrirEnvoyer(profilParticipant.id)} src={pictos.ajout_contact_icone} alt="" width={'35px'} style={{ cursor: 'pointer' }} />
                                                </Col>

                                                :
                                                profilParticipant.is_contact_status === 'accepted' ?
                                                    <Col className='p-0 d-flex align-items-center justify-content-end' style={{ width: '' }}>
                                                        {circle ?
                                                            <CircularProgress />
                                                            :
                                                            <Button onClick={() => chatSelect(chat, dispatch, navigationPage, `/chat/${contactChat[0].conversation.id}/${contactChat[0].conversation.room}/0`, accessToken, setCircle)} className='p-0 px-2 py-1' style={{ borderRadius: '50px', backgroundColor: '#271d67', color: '', border: 'none' }}>{'Message'}</Button>
                                                        }

                                                    </Col>
                                                    :

                                                    null
                                        }

                                        <Col xs={100} className='nom-profil1 mt-1'>
                                            <Row className='d-flex align-items-center'>
                                                <Col className='profil-nom p-0'>{upperFirst(toLower(profilParticipant.user.prenom)) + ' ' + upperFirst(toLower(profilParticipant.user.nom))}</Col>

                                            </Row>
                                            <Row className=''>
                                                <Col className='p-0'>{upperFirst(toLower(profilParticipant.fonction))}<br />{profilParticipant.raison_sociale === '' || profilParticipant.raison_sociale === null ? !profilParticipant.from_structure ? upperFirst(profilParticipant.organisation.nom.toLowerCase()) : profilParticipant.organisation.adresse_organisation.ville : upperFirst(profilParticipant.raison_sociale)}</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='coordonnees-container' style={{ margin: '', textAlign: '' }}>
                                        <Col className=''>
                                            <Row className='coordonnee-info'>
                                                {profilParticipant.is_contact_status !== 'accepted' ?
                                                    null
                                                    :
                                                    <Col className='coordonnees-titre p-0'>Coordonnées</Col>
                                                }

                                            </Row>
                                            {profilParticipant.parametres.filter((item) => item.parametre === "TELEPHONE")[0].etat === true ?
                                                <>
                                                    <Row className='d-flex align-items-center'>
                                                        {profilParticipant.coordonnes.telephone.telephone_fixe === null || profilParticipant.coordonnes.telephone.telephone_fixe === 'None' ?
                                                            <>
                                                            </>
                                                            :
                                                            <>
                                                                <Col className='icone p-0' sm='auto'>
                                                                    <img src={pictos.tel_icone} alt="" />
                                                                </Col>
                                                                <Col className='p-0'>
                                                                    {profilParticipant.coordonnes.telephone.telephone_fixe}
                                                                </Col>
                                                            </>
                                                        }


                                                    </Row>
                                                    <Row className='d-flex align-items-center'>
                                                        {profilParticipant.coordonnes.telephone.telephone_portable === null || profilParticipant.coordonnes.telephone.telephone_portable === 'None' ?
                                                            <>
                                                            </>
                                                            :
                                                            <>
                                                                <Col className='icone mt-2 p-0' sm='auto'>
                                                                    <img src={pictos.tel_icone} alt="" />
                                                                </Col>
                                                                <Col className='p-0'>
                                                                    {profilParticipant.coordonnes.telephone.telephone_portable}
                                                                </Col>
                                                            </>
                                                        }

                                                    </Row>
                                                </>
                                                :
                                                null
                                            }

                                            {profilParticipant.parametres.filter((item) => item.parametre === "EMAIL")[0].etat === true ?
                                                profilParticipant.coordonnes.email === null ?
                                                    null
                                                    :
                                                    <Row className='d-flex align-items-center'>
                                                        <Col className='icone mt-2 p-0' sm='auto'>
                                                            <img src={pictos.mail_icone} alt="" />
                                                        </Col>
                                                        <Col className='p-0'>

                                                            <a style={{ textDecoration: 'none', color: '#271d67' }} href={`mailto:${profilParticipant.coordonnes.email}`}>{profilParticipant.coordonnes.email}</a>

                                                        </Col>
                                                    </Row>
                                                :
                                                null
                                            }

                                            <Row className='d-flex align-items-center'>
                                                <Col className='p-0'>
                                                    {reseauSociaux(profilParticipant.reseausociaux).map((item, index) =>

                                                        item.url !== null && item.url !== 'null' && item.url !== undefined ?
                                                            item.url !== '' ?
                                                                <div key={index} className='icone mt-2' style={{ display: 'inline-block', width: '35px' }}>

                                                                    <NavLink to={`${item.url}`} target='_blank'>
                                                                        <img src={item.logo} alt="" />
                                                                    </NavLink>

                                                                </div>
                                                                :
                                                                null
                                                            :
                                                            null
                                                    )}
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col className='mt-2 p-0'>

                                                    {
                                                        profilParticipant.from_structure ?
                                                            null
                                                            :

                                                            // <NavLink to={profilParticipant.organisation.site_web === '' ? 'https://google.com' : profilParticipant.organisation.site_web} target='_blank'>
                                                            <img onClick={param.contact === '1' || profilParticipant.organisation.id_exposant === '' ? () => ('') : () => partenaireSelect(profilParticipant.organisation.id_exposant)} src={profilParticipant.organisation.logo} alt="logo" height={'80px'} style={{ margin: 'auto', cursor: param.contact === '1' || profilParticipant.organisation.id_exposant === '' ? 'default' : 'pointer' }} />


                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className='container-droite'>
                                <Container>
                                    <Row className='profil-coordonnees mb-2' style={{}}>
                                        <Row className='d-flex align-items-center justify-content-end pt-3'>
                                            <Col className='profil-nom nom-profil2 p-0'>{upperFirst(profilParticipant.user.prenom.toLowerCase()) + ' ' + upperFirst(profilParticipant.user.nom.toLowerCase())}</Col>
                                        </Row>
                                        <Row className='nom-profil2'>
                                            <Col>
                                                <Row>
                                                    <Col className='p-0'>
                                                        {upperFirst(profilParticipant.fonction.toLowerCase())}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className='p-0'>
                                                        {profilParticipant.raison_sociale === '' || profilParticipant.raison_sociale === null ? !profilParticipant.from_structure ? upperFirst(profilParticipant.organisation.nom.toLowerCase()) : upperFirst(profilParticipant.organisation.adresse_organisation.ville.toLowerCase()) : upperFirst(profilParticipant.raison_sociale.toLowerCase())} <i style={{ opacity: '0' }}>.</i>
                                                    </Col>
                                                </Row>

                                            </Col>

                                        </Row>
                                    </Row>
                                    {profilParticipant.presentation === '' || profilParticipant.presentation === null || profilParticipant.presentation === undefined ?
                                        null
                                        :
                                        <Row className='coordonnee-info'>
                                            <Row className="coordonnees-titre-infos">
                                                <Col className="coordonnees-titre p-0">Présentation</Col>
                                            </Row>
                                            <Row className="pb-0" style={{ textAlign: 'left' }}>
                                                <Col className='p-0' style={{ wordBreak: 'break-word', height: 'fit-content' }} dangerouslySetInnerHTML={{ __html: upperFirst(profilParticipant.presentation) }}></Col>
                                            </Row>
                                        </Row>
                                    }

                                    {profilParticipant.expertises.length ?
                                        <Row className='coordonnee-info'>
                                            <Row className="coordonnees-titre-infos">
                                                <Col className="coordonnees-titre p-0">Expertises</Col>
                                            </Row>

                                            {profilParticipant.expertises.map((item, index) =>

                                                <Row key={index} >
                                                    <Col sm='auto' className="list-profil p-0">• {upperFirst(toLower(item))}</Col>

                                                </Row>




                                            )}
                                        </Row>
                                        :
                                        null
                                    }
                                    {profilParticipant.centreinterets.length ?
                                        <Row className='coordonnee-info'>
                                            <Row className="coordonnees-titre-infos">
                                                <Col className="coordonnees-titre p-0">Centres d'intêrets</Col>
                                            </Row>

                                            {profilParticipant.centreinterets.map((item, index) =>


                                                <Row key={index} className="">
                                                    <Col className='p-0'>• {upperFirst(toLower(item))}</Col>

                                                </Row>




                                            )}
                                        </Row>
                                        :
                                        null
                                    }
                                    <Row className='coordonnee-info'></Row>
                                </Container>
                            </div>
                        </Row>
                        {param.contact === '0' ?

                            <Row className='footer-profil bg'>
                                <Container>
                                    {profilParticipant.intervetions.length ?
                                        <Row className='intervention-titre d-flex align-items-center mt-3'> <p>{'Mes interventions'}</p>  </Row>
                                        :
                                        null
                                    }

                                    {
                                        profilParticipant.intervetions.length ?
                                            profilParticipant.intervetions.map((item2, index) =>

                                                <Row key={index} className='intervention-item py-3 d-flex justify-content-start' onClick={() => programSelect(accessToken, item2.id)} style={{ cursor: 'pointer' }}>
                                                    <Container className='d-flex' style={{ margin: 'auto', width: '80%', fontSize: '.8rem' }}>
                                                        <Row>

                                                            <Col sm='auto intervention-temp px-1' className='' style={{ width: 'fit-content', height: 'fit-content', backgroundColor: couleurTheme(item2.categorie), color: 'white' }}>

                                                                {item2.heure_fin === '' || item2.heure_fin === undefined ? item2.heure_deb : item2.heure_deb + ' - ' + item2.heure_fin}

                                                            </Col>
                                                            <Col className='h6 ' style={{ color: couleurTheme(item2.categorie) }}>

                                                                {upperFirst(toLower(item2.titre))}

                                                            </Col>
                                                        </Row>
                                                    </Container>

                                                </Row>


                                            )
                                            : <></>
                                    }

                                </Container>
                            </Row>
                            :
                            null
                        }

                    </>
                }




            </Container >
            {/* 
            
             */}
        </div >
    );
};

export default CompteParticipant;