import { DeleteForever, MoreVert, Warning } from "@material-ui/icons";
import { Box, Divider, IconButton, ListItemDecorator, Menu, MenuItem, Typography } from "@mui/joy";
import _ from "lodash";
import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";

const ChatReceive = (props) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handle = (e) => {

        try {
            props.delete(selectMessage);
            setOpenSupprimer(false)
            document.getElementById(selectMessage).style.display = 'none'

        } catch (error) {
            console.log('erreur de suppression')
        }


    };
    const [openSupprimer, setOpenSupprimer] = useState(false)
    const [selectMessage, setSelectMessage] = useState(props.message.id)

    const ouvrirSupprimer = (id) => {
        setSelectMessage(id)
        console.log('le id : ', selectMessage)
        setOpenSupprimer(true)

    }


    return (
        <Container className='chat-receive' id={props.message.id}>
            {/* Modal confirmation suppression message */}
            <Modal show={openSupprimer} style={{ fontFamily: 'Poppins' }} onHide={() => setOpenSupprimer(false)}>
                <Modal.Header closeVariant='white' closeButton style={{ color: 'white', backgroundColor: '#271d67' }}>
                    <h4>

                        Confirmation

                    </h4>
                </Modal.Header>
                <form>
                    <Modal.Body>

                        Voulez-vous vraiment supprimer ce message ?


                        <div className='d-flex justify-content-end mt-3'>

                            <Button className='btn-recup' onClick={handle}>Confirmer</Button>

                            <Button className='btn-recup2 mx-2' variant='secondary' onClick={() => setOpenSupprimer(false)}>Fermer</Button>
                        </div>

                    </Modal.Body>
                </form>
            </Modal>

            <Menu
                id="positioned-demo-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                aria-labelledby="positioned-demo-button"
                placement="bottom-end"
            >
                <MenuItem onClick={() => ouvrirSupprimer(props.message.id)} variant="soft" color="danger">
                    <ListItemDecorator sx={{ color: 'inherit' }}>
                        <DeleteForever />
                    </ListItemDecorator>{' '}
                    Supprimer
                </MenuItem>
            </Menu>


            <Row className='d-flex justify-content-center' style={{ color: 'grey', fontSize: '.7rem' }}>
                {props.date}
            </Row>
            <Row className='d-flex justify-content-start'>
                <div className="chat-box">
                    <Container>
                        <Row>

                            <IconButton
                                id="positioned-demo-button"
                                aria-controls={open ? 'positioned-demo-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                variant="plain"
                                color="neutral"
                                onClick={handleClick}
                                sx={{ color: 'grey', width: 'fit-content', paddingLeft: '0', paddingRight: '0' }}
                                className="col-sm-auto"                            >
                                <MoreVert />
                            </IconButton>


                            <Col className="p-0 d-flex align-items-center" style={{ whiteSpace: 'break-spaces' }}>
                                {props.message.message}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Row>

        </Container>
    );


};

export default ChatReceive;
