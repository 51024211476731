import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { pictos } from "../assets/img/pictos";
import { Input } from "@mui/joy";
import { SearchOutlined } from "@material-ui/icons";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Badge, CircularProgress } from "@material-ui/core";
import { upperFirst } from "lodash";
import "../styles/message.css";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket from "react-use-websocket";
import { chatSelect, listMessage, refreshToken } from "../redux/profilService";
import { SOCKETURL } from "../redux/api";
import moment from 'moment/moment';
import Navigation from "../components/Navigation";
import { affichageDate, toNormalForm } from "./fonctions";
import Breadcrumb from "../components/Breadcrumb";
import Chemin from "../components/Chemin";
import ErrorPopup from "../components/ErrorPopup";
import textConnect from '../textConnect.json'

const Messages = () => {
  const monProfil = useSelector((state) => state.profil.profil);
  const accessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector(state => state.user.refresh)
  const [conversation, setConversation] = useState([]);

  const [filtreMot, setFiltreMot] = useState('')

  const [loading, setLoading] = useState(true)

  function filtreMessages(e) {
    setFiltreMot(e.target.value)
  }

  const [data, setData] = useState()
  useWebSocket(
    `${SOCKETURL}/liste/${monProfil.id}/?token=${accessToken}`,
    {
      onOpen: () => {
        console.log("Connected!");

      },
      onClose: () => {
        console.log("Disconnected!");

      },
      onError: () => {
        console.log("Erreur websocket");

        // setInterval(() => {
        //   navigationPage('/messagerie', '_blank')
        //   console.log('000')
        // }, 1000);
      },
      onMessage: (e) => {
        // const data = JSON.parse(e.data);
        setData(JSON.parse(e.data))

        // switch (data.type) {
        //   case "dernier_message":
        //     let conversations = conversation.filter((conver) => {
        //       return data.id !== conver.id
        //     })
        //     // setConversation((prev) => [data, ...conversations])
        //     console.log('conversation(s)......')
        //     console.log(conversations)
        //     console.log('data...........')
        //     console.log(data)
        //     console.log('conversation...............')
        //     console.log(conversation)
        //     break;
        //   default:
        //     console.log(data)
        //     break;
        // }
      },
    }
  );

  // useEffect(() => {

  //   // console.log('dans le hooks', data)


  // }, [data])

  const [openError, setOpenError] = useState(false)
  const [msgError, setMsgError] = useState('')

  const closeErrorHandle = () => {
    setOpenError(false)
  }

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };

  }, [netStatus])


  useEffect(() => {
    dispatch({
      type: 'Profil/addChemin',
      payload: breadcrumbs_temp
    })

    listMessage(accessToken).then((data) => {
      setConversation([...data]);
      setLoading(false)
      if (!netStatus) {
        setMsgError(textConnect.internet)
        setOpenError(true)

      }
    }).catch((erreur) => {
      setLoading(false)

      if (!netStatus) {
        setMsgError(textConnect.internet)
        setOpenError(true)

      }
      if (erreur.response) {

        if (erreur.response.status !== 401) {
          setMsgError(textConnect.error)
          setOpenError(true)
        }

        if (erreur.response.status === 401) {
          refreshToken(refresh_token).then(
            async (reponse) => {
              dispatch(
                {
                  type: 'user/addRefresh',
                  payload: reponse.data.refresh
                }
              );
              dispatch(
                {
                  type: 'user/addAccess',
                  payload: reponse.data.access
                }
              )
              console.log('token refresh...')

            })



        }

      }
    });
  }, [data]);

  const dispatch = useDispatch()
  const navigationPage = useNavigate()
  const location = useLocation()
  //Breadcrumbs
  const breadCrumbs = useSelector(state => state.profil.chemin)
  const breadcrumbs_temp = breadCrumbs.filter((item => item.nom === 'Accueil'))
  breadcrumbs_temp.push({ url: location.pathname, nom: 'Messages' })

  return (
    <Container fluid>
      <Row className="">
        <Navigation
          button={false}
          titre={'Messages'}
          icone={pictos.messagerie_rond_icone}
          photo={monProfil.photo_path}
          retour='dashboard'
          menus={true}

        />

      </Row>

      <Row className='p-0'>
        <Chemin
          breadCrumbs={breadcrumbs_temp}
        />

      </Row>
      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />
      {loading ?
        <div className="d-flex justify-content-center">
          <CircularProgress className="" />
        </div>

        :
        <Row className="container-messagerie d-flex justify-content-center">
          <Row
            className=" mb-4 recherche-container-messages d-flex align-items-center justify-content-center"
            style={{ width: "" }}
          >
            <Input
              onChange={(e) => filtreMessages(e)}
              className="col"
              color="#271d67"
              variant="outlined"
              startDecorator={<SearchOutlined />}
              placeholder="Rechercher un message"
              sx={{
                "--Input-radius": "50px",
                backgroundColor: "#e4e4e4",
                width: "",
                fontFamily: "Poppins",
                color: "#271d67",
              }}
            />
          </Row>


          {conversation.filter((message) => {
            return (message.lastMessage !== undefined && message.lastMessage !== null)
          }).filter((item) => {
            return filtreMot.toLowerCase() === '' ? item : (toNormalForm(item.contact.name.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())))
          }).map((message, id) => {
            let laDate1 = new Date(message.lastMessage.created_at)
            if (message.contact.from_structure) {
              message.contact.photo_organisation = ''
            }
            return (
              <Row
                key={id}
                className="pb-2 message-item d-flex align-items-cegnter justify-content-centerf"
              >
                <NavLink className='' onClick={() => chatSelect(message, dispatch, navigationPage, `/chat/${message.id}/${message.nom_conversation}/1`, accessToken, setLoading)} key={id} style={{
                  textDecorationLine: 'none', width: '100%'
                }}>
                  <Row>
                    <Col sm="auto" className="p-0 mx-2 photo-profil-contact d-flex align-items-center" style={{ width: 'fit-content' }}>

                      <Avatar
                        id="photoProfil"
                        className=""
                        src={message.contact.photo}
                        style={{
                          width: "9vh",
                          height: "9vh",
                          backgroundColor: "",
                        }}
                      />

                    </Col>
                    <Col className="p-0" style={{}}>
                      <Row className="">
                        <Col sm='auto' className="noms-participant text-capitalize" style={{ width: 'fit-content', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' }}>
                          {upperFirst(message.contact.name.toLowerCase())}
                        </Col>

                      </Row>



                      <Col className="" style={{ wordBreak: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical' }}>
                        <b>{message.lastMessage.du_participant.id === monProfil.id ? 'vous :' : null}</b>  {`${message.lastMessage?.message.trim()}`}
                      </Col>

                      <Row>
                        <Col>
                          {
                            message.contact.from_structure ?
                              null
                              :
                              <img className="col" src={message.contact.photo_organisation} alt="" style={{ height: '40px', width: 'auto' }} />
                          }

                        </Col>
                      </Row>


                    </Col>
                    <Col sm='auto' style={{ color: '', fontFamily: 'Poppins', fontSize: '.65rem', width: 'fit-content', marginRight: '20px' }} className=' p-0 d-flex justify-content-end mt-1'>

                      {affichageDate(laDate1)}
                    </Col>
                  </Row>

                </NavLink>

              </Row>

            );
          })}


        </Row>
      }

    </Container>
  );
};

export default Messages;
