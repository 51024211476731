import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch } from "react-redux";



let initialState = {
    profil: {},
    notifications: [],
    configurations: {},
    chat: {},
    chatEvenement: [],
    chatStand: [],
    idConversation: "",
    contenusTexte: {},
    chemin: [{ url: '/', nom: 'Accueil' }],
    historique: '',
    rdv: '',
}


const profilSlice = createSlice({
    name: 'Profil',
    initialState,
    reducers: {
        addProfil: (state, action) => {
            state.profil = action.payload
        },

        addNotifications: (state, action) => {
            state.notifications = (action.payload)
        },
        addConfig: (state, action) => {
            state.configurations = (action.payload)
        },
        addChat: (state, action) => {
            state.chat = (action.payload)
        },
        evenentChat: (state, action) => {
            state.chatEvenement = (action.payload)
        },
        standChat: (state, action) => {
            state.chatStand = (action.payload)
        },
        idChat: (state, action) => {
            state.idConversation = (action.payload)
        },
        addTextContent: (state, action) => {
            state.contenusTexte = (action.payload)
        },
        addChemin: (state, action) => {
            state.chemin = (action.payload)
        },
        addHistorique: (state, action) => {
            state.historique = (action.payload)
        },
        addRdv: (state, action) => {
            state.rdv = (action.payload)
        },
        resetProfil: () => initialState


    }
})



export const { addProfil, addNotifications, addConfig, addChat, evenentChat, standChat, idChat, addTextContent, addChemin, addHistorique, addRdv, resetProfil } = profilSlice.actions;
export default profilSlice.reducer;