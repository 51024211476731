import React, { useContext, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import '../styles/programme.css';
import { pictos } from '../assets/img/pictos';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { participantsRedux, programmeSelectRedux, programmesRedux } from '../redux/evenementsServices';
import { couleurTheme, pictosCategorie, toNormalForm } from './fonctions';
import { CircularProgress, LinearProgress, Menu, MenuItem, Typography } from '@material-ui/core';
import _, { toLower } from 'lodash';
import { upperFirst } from 'lodash';
import SearchInput from '../components/SearchInput';
import { useEffect } from 'react';
import { refreshToken } from '../redux/profilService';
import ErrorPopup from '../components/ErrorPopup';
import Breadcrumb from '../components/Breadcrumb';
import { BreadcrumbContext } from '../Context';
import Chemin from '../components/Chemin';
import textConnect from '../textConnect.json'


const Programme = () => {
    const location = useLocation()
    //Breadcrumbs
    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.nom === 'Accueil'))
    breadcrumbs_temp.push({ url: location.pathname, nom: 'Programme' })

    const evenementSelected = useSelector(state => state.evenements.evenementSelect)
    const leProgrammeInit = _.sortBy(useSelector(state => state.evenements.programmes), [function (val) { return val.heure_deb }])
    const [leProgramme, setLeProgramme] = useState(leProgrammeInit)

    const monProfil = useSelector(state => state.profil.profil)


    const AccessToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)
    const dispatch = useDispatch()
    const navigationPage = useNavigate()

    const [filtreMot, setFiltreMot] = useState('')


    const [loading, setLoading] = useState(true)

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])


    //Fonction filtre barre de recherche
    function filtreProgrammes(e) {
        setFiltreMot(e.target.value)
        console.log(filtreMot)

    }

    function annulerFiltre() {
        setLeProgramme(leProgrammeInit)
        setAnchorEl(null)
        setCatCheck([])
    }





    // Menu filtre
    // var catCheck = [{}]
    const [catCheck, setCatCheck] = useState([])


    function FiltrerCategorie(categorie = [], tableau = []) {

        let resultat = []
        if (categorie.length > 1) {
            console.log('superieur a 1..................')
            console.log(categorie)
            let tabTemp = []


            for (let index = 0; index < categorie.length; index++) {
                // console.log('itemeeee', categorie[index])
                tableau.filter(item =>
                    item.categorie.label.includes(categorie[index])).map((item2) =>
                        tabTemp.push(item2)
                    )

                resultat = tabTemp
                console.log(resultat)


            }

        }
        else {
            resultat = tableau.filter(item =>
                item.categorie.label.includes(categorie)
            )
        }


        // console.log('le resultat : ', resultat)
        setLeProgramme(resultat)
        // setAnchorEl(null)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //La liste unique des catégories filtré par ordre alphabétique
    const leProgrammeUnique = [...new Map(leProgrammeInit.map(item => [item.categorie.label, item])).values()].sort(function (a, b) {

        if (upperFirst(a.categorie.label) < upperFirst(b.categorie.label)) {

            return -1
        }
        if (upperFirst(a.categorie.label) > upperFirst(b.categorie.label)) {
            return 1
        }
        return 0
    })

    const handleCheck = (e, value) => {

        if (e.target.checked) {

            setCatCheck((old) => [...old, value])
        }
        else {
            setCatCheck(catCheck.filter((item) => item !== value))
            console.log(catCheck)
        }


    }
    useEffect(() => {

        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })

        // breadCrumbs.setPages((prev) => prev.filter((item => item.nom === 'Accueil')).concat({ url: location.pathname, nom: 'Programme' }))
        // console.log('dans le hook.......................')
        // console.log(catCheck)
        FiltrerCategorie(catCheck, leProgrammeInit)

    }, [catCheck])
    const participantsList = (token, id) => {

        participantsRedux(token, id).then((reponse) => {
            // setLoading(false)
            dispatch(
                {
                    type: 'evenements/addParticipants',
                    payload: reponse.data
                }
            );
            window.scrollTo(0, 0)

        }).catch((erreur) => {
            // setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            participantsList(token, id)
                        })

                }
            }

        })
    }
    const programSelect = (token, id) => {
        setLoading(true)
        participantsList(token, evenementSelected.id)
        programmeSelectRedux(token, id).then((reponse) => {
            dispatch(
                {
                    type: 'evenements/addProgrammeSelect',
                    payload: reponse.data
                }
            );
            // window.open('/programmeDetail', '_top')
            navigationPage('/programme/' + id + '/0')
            setLoading(false)
            window.scrollTo(0, 0)
        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setLoading(false)
                            programSelect(token, id)
                        })

                }
            }
        })
    }

    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')

    const closeErrorHandle = () => {
        setOpenError(false)
    }
    useEffect(() => {
        setLoading(true)
        programmesRedux(AccessToken, evenementSelected.id).then(async (response) => {
            dispatch(
                {
                    type: 'evenements/addProgramme',
                    payload: response.data
                }
            );
            navigationPage('/programmes')
            setLoading(false)
            window.scrollTo(0, 0)

        }).catch((erreur) => {
            setLoading(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {

                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                        })



                }
            }
        })
    }, [])
    return (
        <Container fluid>

            <ErrorPopup
                message={msgError}
                open={openError}
                close={() => closeErrorHandle()}
            />
            <Menu
                className='menu-filtre'
                id="filtre-menu"
                aria-labelledby="btn-filtre"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                style={{}}

            >

                <Typography className='mx-3 border-bottom' style={{ fontFamily: 'Poppins-bold' }}>{" Filtrer par catégorie :"}</Typography>
                {leProgrammeUnique.map((item, index) =>


                    item.categorie.label === '' || item.categorie.label === null ?
                        null
                        : <MenuItem key={index} className='' style={{ fontFamily: 'Poppins' }} >
                            <Col>
                                <label htmlFor={item.id}>{upperFirst(item.categorie.label)}</label>
                            </Col>
                            <Col className='' sm='auto'>
                                <input id={item.id} checked={catCheck.filter((cat) => cat === item.categorie.label).length ? true : false} type="checkbox" name="" onChange={(e) => handleCheck(e, item.categorie.label)} />
                            </Col>
                        </MenuItem>

                )}
                <MenuItem className='border-top' style={{ fontFamily: 'Poppins' }} onClick={() => annulerFiltre()}>Annuler le filtre</MenuItem>
            </Menu>
            <Row>
                <Navigation
                    button={true}
                    titre={'Programme'}
                    icone={pictos.programme_rond_icone}
                    evenement={evenementSelected.photo}
                    photo={monProfil.photo_path}
                    retour='dashboard'
                    selected={1}
                    open={(!open).toString()}
                    menus={true}

                />

            </Row>

            <Row className='p-0'>
                <Chemin
                    breadCrumbs={breadcrumbs_temp}
                />
            </Row>
            <Row className='programme-container d-flex justify-content-center'>
                {loading ?
                    <div className='d-flex justify-content-center'>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {leProgramme.length ?
                            <Row className=' mb-4 d-flex align-items-center justify-content-center'>

                                <SearchInput
                                    placeholder={'Rechercher'}
                                    filtre={filtreProgrammes}
                                />


                                <Col sm='auto' xl={''} className='' style={{ width: 'fit-content' }}>
                                    <NavLink style={{ textDecoration: 'none', color: '#271d67' }} aria-controls={open ? 'filtre-menu' : undefined} aria-haspopup={true} aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                        <Row className='d-flex justify-content-center' style={{ textAlign: 'center', height: '20px', margin: '' }}>  {'Filtrer'} </Row>
                                        <img className='row' src={pictos.filtrer_icone} alt="" style={{ width: '30px', margin: 'auto' }} />
                                    </NavLink>
                                </Col>

                            </Row >
                            :
                            null
                        }

                        <Row xs={1} className='d-flex align-items-center justify-content-center'>
                            {leProgramme.length ?
                                leProgramme.filter((item) => {
                                    return filtreMot.toLowerCase() === '' ? item : (toNormalForm(item.titre.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.description.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.theme.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())))
                                }).length === 0 ?
                                    <p className='' style={{ textAlign: 'center', color: '#271d67', fontSize: '120%' }}>Aucun résultat trouvé</p>
                                    :
                                    leProgramme.filter((item) => {
                                        return filtreMot.toLowerCase() === '' ? item : (toNormalForm(item.titre.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.description.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.theme.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())))
                                    }).map((item, index) =>
                                        <Row key={index} className='programme-item ' >
                                            <Col className=''>
                                                <Row className='d-flex align-items-center'>
                                                    <Col sm='auto   ' className='programme-date' style={{ backgroundColor: couleurTheme(item.categorie), marginLeft: '10px' }}>{item.heure_fin === '' || item.heure_fin === undefined || item.heure_fin === null ? item.heure_deb : item.heure_deb + " - " + item.heure_fin}</Col>

                                                    <Col className='stand1 p-0' style={{ width: '' }}>
                                                        {item.categorie.for_room ?
                                                            (item.stand.id === '' || item.stand.id === undefined) && item.salle === '' ?

                                                                null
                                                                :
                                                                <div className=' d-flex align-items-center'>
                                                                    <img title={item.stand.id === undefined || item.stand.id === '' ? null : 'Voir le stand'} onClick={() => navigationPage(item.stand.id === undefined || item.stand.id === '' ? '' : '/planStand/' + item.stand.id)} src={pictos.lieu_icone} alt="" style={{ height: '25px', width: 'auto', cursor: item.stand.id === undefined || item.stand.id === '' ? '' : 'pointer' }} />
                                                                    <div className=' pt-1 d-flex align-items-center'>{`${upperFirst(toLower(item.salle))}`}</div>
                                                                </div>
                                                            :
                                                            item.stand.code === '' || item.stand.code === undefined ?
                                                                null
                                                                :
                                                                <div className='d-flex align-items-center'>
                                                                    <img title='Voir le stand' onClick={() => navigationPage(item.stand.id === undefined || item.stand.id === '' ? '' : '/planStand/' + item.stand.id)} src={pictos.lieu_icone} alt="" style={{ height: '25px', width: 'auto', cursor: 'pointer' }} />
                                                                    <div className=' pt-1 d-flex align-items-center'>{`${upperFirst(toLower(item.stand.code))}`}</div>
                                                                </div>
                                                        }



                                                    </Col>
                                                    <img className='col-sm-auto px-2 mt-3' src={pictosCategorie(item.categorie)} alt="" style={{ height: '', width: 'auto', paddingRight: '0', paddingLeft: '0' }} />
                                                </Row>
                                                <div className='' style={{ cursor: 'pointer' }} onClick={() => programSelect(AccessToken, item.id)}>

                                                    <Row className='mb-1' style={{ marginTop: '-12px' }}>
                                                        <Row className='stand2'>
                                                            <Col className=' p-0  mb-1 px-1' style={{ width: '' }}>
                                                                {item.categorie.for_room ?
                                                                    (item.stand.id === '' || item.stand.id === undefined) && item.salle === '' ?

                                                                        null
                                                                        :
                                                                        <div className='d-flex align-items-center'>
                                                                            <img title={item.stand.id === undefined || item.stand.id === '' ? null : 'Voir le stand'} onClick={() => navigationPage(item.stand.id === undefined || item.stand.id === '' ? '' : '/planStand/' + item.stand.id)} src={pictos.lieu_icone} alt="" style={{ height: '25px', width: 'auto', cursor: item.stand.id === undefined || item.stand.id === '' ? '' : 'pointer' }} />
                                                                            <div className=' pt-1 d-flex align-items-center'>{`${upperFirst(toLower(item.salle))}`}</div>
                                                                        </div>
                                                                    :
                                                                    item.stand.code === '' || item.stand.code === undefined ?
                                                                        null
                                                                        :
                                                                        <div className='d-flex align-items-center'>
                                                                            <img title='Voir le stand' onClick={() => navigationPage(item.stand.id === undefined || item.stand.id === '' ? '' : '/planStand/' + item.stand.id)} src={pictos.lieu_icone} alt="" style={{ height: '25px', width: 'auto', cursor: 'pointer' }} />
                                                                            <div className=' pt-1 d-flex align-items-center'>{`${upperFirst(toLower(item.stand.code))}`}</div>
                                                                        </div>
                                                                }



                                                            </Col>
                                                        </Row>
                                                        <Col sm='auto' className='' style={{ color: couleurTheme(item.categorie) }}>{item.categorie.label === '' ? upperFirst(toLower(item.theme)) : item.theme === '' || item.theme === null ? upperFirst(toLower(item.categorie.label)) : `${upperFirst(toLower(item.categorie.label))} - ${upperFirst(toLower(item.theme))}`}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='programme-titre' style={{ color: couleurTheme(item.categorie) }}>{upperFirst(toLower(item.titre))}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='programme-description' style={{ display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            <p style={{ color: '#271d67' }} dangerouslySetInnerHTML={{ __html: upperFirst(item.description) }} />
                                                        </Col>
                                                        {/* <Col sm={''} className=' border programme-description'><p className=''>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto reprehenderit dolores expedita, enim doloremque corporis iste eveniet unde! Labore, unde?</p></Col> */}
                                                    </Row>
                                                </div>

                                            </Col>
                                        </Row>
                                    )
                                :
                                <div className='d-flex align-items-center justify-content-center' style={{ textAlign: 'center', width: '100%', height: '100%', color: '#271d67', fontSize: '150%' }}>{" Vous n'avez aucun programme"}</div>
                            }

                        </Row>

                    </>



                }

            </Row >


        </Container >
    );
};

export default Programme;