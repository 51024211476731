import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { CircularProgress } from '@material-ui/core';
import { contacterBec } from './fonctions';
import { useDispatch, useSelector } from 'react-redux';
import Navigation from '../components/Navigation';
import "../styles/nousContacter.css";
import Breadcrumb from '../components/Breadcrumb';
import { useLocation } from 'react-router-dom';
import ErrorPopup from '../components/ErrorPopup';
import Chemin from '../components/Chemin';
import { refreshToken } from '../redux/profilService';
import textConnect from '../textConnect.json'

const NousContacter = () => {
    const accessToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)
    const monProfil = useSelector(state => state.profil.profil)

    const [leMessage, setLeMessage] = useState('')
    const [objetMessage, setObjetMessage] = useState('')
    const [envoyer, setEnvoyer] = useState(false)
    const [circle, setcircle] = useState(false)
    const location = useLocation()
    const texteIntro = useSelector(state => state.profil.contenusTexte !== undefined ? state.profil.contenusTexte.message_contact : "")
    const dispatch = useDispatch()
    //Breadcrumbs
    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.nom === 'Accueil'))
    breadcrumbs_temp.push({ url: location.pathname, nom: 'Nous contacter' })

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])


    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })

        if (leMessage === '' || objetMessage === '') {
            setEnvoyer(true)
        }
        if (leMessage !== '' && objetMessage !== '') {
            setEnvoyer(false)
        }

    }, [leMessage, objetMessage])




    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')

    const closeErrorHandle = () => {
        setOpenError(false)
    }
    const contacter = (objet, message, token) => {
        setcircle(true)
        contacterBec(objet, message, token).then((response) => {
            setcircle(false)
            setLeMessage('')
            setObjetMessage('')
            setOpenEnvoi(true)
            // console.log(response)
        }).catch((erreur) => {
            setcircle(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
            }
            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                }
                if (erreur.response.status === 401) {
                    refreshToken(refresh_token).then(
                        async (reponse) => {
                            dispatch(
                                {
                                    type: 'user/addRefresh',
                                    payload: reponse.data.refresh
                                }
                            );
                            dispatch(
                                {
                                    type: 'user/addAccess',
                                    payload: reponse.data.access
                                }
                            )
                            console.log('token refresh...')

                            setcircle(false)
                            contacter(objet, message, token)
                        })

                }
            }
        })
    }
    const [openEnvoi, setOpenEnvoi] = useState(false)
    return (
        <Container fluid>

            <Modal show={openEnvoi} style={{ fontFamily: 'Poppins' }} onHide={() => setOpenEnvoi(false)}>
                <Modal.Header closeVariant='white' closeButton style={{ color: 'white', backgroundColor: '#271d67' }}>
                    <h4>
                        Information
                    </h4>
                </Modal.Header>
                <Modal.Body className='py-5' style={{ fontSize: '' }}>
                    <p>
                        Nous vous remercions de nous contacter. <br /> Notre équipe revient vers vous dans le plus brefs délais.
                    </p>

                </Modal.Body>

            </Modal>

            <ErrorPopup
                message={msgError}
                open={openError}
                close={() => closeErrorHandle()}
            />
            <Row>
                <Navigation
                    button={false}
                    titre={'Nous contacter'}

                    photo={monProfil.photo_path}
                    retour='dashboard'
                    menus={true}

                />
            </Row>

            <Row className='p-0'>
                <Chemin
                    breadCrumbs={breadcrumbs_temp}
                />

            </Row>

            <Row className='mt-3 mb-4 nous-contacter-container ' style={{}}>
                <Container>
                    <Row className='d-flex align-items-center mb-5' style={{ textAlign: 'justify' }}>
                        <Col dangerouslySetInnerHTML={{ __html: texteIntro }}></Col>
                    </Row>
                    <Row>
                        <Form>
                            <Form.Group className="mb-3 py-3" style={{ borderTop: '2px solid #01bee6' }}>
                                <Form.Label> <strong>Objet</strong> </Form.Label>
                                <Form.Control value={objetMessage} style={{ backgroundColor: '#d2e9fb', borderRadius: '0', border: 'none', color: '#271d67' }} id='objet-message' onChange={(e) => setObjetMessage(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3 py-3" style={{ borderTop: '2px solid #01bee6' }}>
                                <Form.Label> <strong>Message</strong> </Form.Label>
                                <Form.Control value={leMessage} style={{ backgroundColor: '#d2e9fb', borderRadius: '0', border: 'none', color: '#271d67' }} id='message' as="textarea" rows={7} onChange={(e) => setLeMessage(e.target.value)} />
                            </Form.Group>
                            <Form.Group className='d-flex justify-content-end'>
                                {circle ?
                                    <CircularProgress />
                                    :
                                    <Button className='px-3 py-1' style={{ backgroundColor: '#271d67', borderRadius: '50px', border: 'none', padding: '0' }} disabled={envoyer} onClick={() => contacter(objetMessage, leMessage, accessToken)}>Envoyer</Button>
                                }

                            </Form.Group>
                        </Form>

                    </Row>
                </Container>


            </Row>
        </Container >
    );
};

export default NousContacter;