import React, { useContext, useEffect, useState } from 'react';
import LOGO from '../assets/img/logo.png'
import { pictos } from '../assets/img/pictos';
import { Container, Nav, Navbar, Row, Col } from 'react-bootstrap';
import "../styles/navigation.css"
import { NavLink, useNavigate } from 'react-router-dom';
import { Menu, MenuItem, styled } from '@material-ui/core';
import { LoggedContext, NotifNbreContext } from '../Context';
import { deconnexion } from '../pages/fonctions';
import { configInfos, contactsRedux, demandesRedux, invitationsRedux, refreshToken } from '../redux/profilService';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@mui/joy';
import Badge from '@mui/material/Badge';
import Notification from './Notification';
import DashboardButton from './DashboardButton';
import { resetUser } from '../redux/authSlice';
import DashboardMenu from './DashboardMenu';
import { resetEvenements } from '../redux/evenementsSlice';
import { resetProfil } from '../redux/profilSlice';




const Navigation = (props) => {

    //Déconnexion
    const loggedContext = useContext(LoggedContext);
    const navigationPage = useNavigate()

    const userToken = useSelector(state => state.user.access)
    const refresh_token = useSelector(state => state.user.refresh)
    const dispatch = useDispatch()

    const eventSelected = useSelector(state => state.evenements.evenementSelect !== undefined ? state.evenements.evenementSelect.id : '')

    const nbreNotif = useContext(NotifNbreContext)
    const userId = useSelector(state => state.profil.profil.id)

    const PoliceDefaut = 'Poppins'





    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);
    const handleClickNotif = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleCloseNotif = () => {
        setAnchorEl2(null);
    };

    // const handleContacts = () => {
    //     contactsRedux(userToken)
    //         .then((reponse) => {
    //             // console.log(reponse.data)

    //             dispatch(
    //                 {
    //                     type: 'Profil/addContacts',
    //                     payload: reponse.data
    //                 }
    //             );

    //             demandesRedux(userToken)
    //                 .then((reponse) => {
    //                     // console.log(reponse.data)

    //                     dispatch(
    //                         {
    //                             type: 'Profil/addDemandes',
    //                             payload: reponse.data
    //                         }
    //                     );

    //                 })

    //             invitationsRedux(userToken)
    //                 .then((reponse) => {
    //                     // console.log(reponse.data)

    //                     dispatch(
    //                         {
    //                             type: 'Profil/addInvitations',
    //                             payload: reponse.data
    //                         }
    //                     );

    //                 })

    //             navigationPage('/contacts')


    //         }).catch((erreur) => {
    //             if (erreur.response) {
    //                 if (erreur.response.status === 401) {
    //                     refreshToken(refresh_token).then(
    //                         async (reponse) => {
    //                             dispatch(
    //                                 {
    //                                     type: 'user/addRefresh',
    //                                     payload: reponse.data.refresh
    //                                 }
    //                             );
    //                             dispatch(
    //                                 {
    //                                     type: 'user/addAccess',
    //                                     payload: reponse.data.access
    //                                 }
    //                             )
    //                             console.log('token refresh...')
    //                             handleContacts()
    //                         })



    //                 }

    //             }
    //         })

    // }



    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            right: 23,
            top: 3,
            border: '1px solid white',
            padding: '0 4px',
            backgroundColor: 'red',
            color: 'white',
            fontFamily: PoliceDefaut,
            // fontSize: '1rem',
            height: 'auto',
            paddingTop: '5%',
            paddingBottom: '5%',
            textAlign: 'center'
        },
    }));

    const contenusBO = useSelector(state => state.profil.contenusTexte)

    const logout = () => {
        deconnexion(loggedContext, navigationPage);
        dispatch(resetUser())
        dispatch(resetEvenements())
        dispatch(resetProfil())
        nbreNotif.setNbre(0)
    }
    return (
        <Container className='navbar-container mb-2 pb-4' style={{ background: `url(${contenusBO.barre_navigation})` }} fluid>
            {props.button ? <DashboardButton selected={props.selected} /> : <></>}
            {props.button ? <DashboardMenu selected={props.selected} open={props.open} /> : <></>}


            {/* Menu notifications  */}
            {/* <Notification anchorEl={anchorEl2} handleClose={handleCloseNotif} open={open2} /> */}

            <Menu
                className='menu-profil'
                id="profil-menu"
                aria-labelledby="btn-profil"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

            >
                {props.profile ? null : <MenuItem onClick={() => navigationPage('/compte')} style={{ fontFamily: PoliceDefaut }} className=''>Mon profil</MenuItem>}
                <MenuItem onClick={() => navigationPage('/parametres')} style={{ fontFamily: PoliceDefaut }}>Paramètres</MenuItem>
                <MenuItem onClick={() => navigationPage('/nousContacter')} style={{ fontFamily: PoliceDefaut }}>Nous contacter </MenuItem>
                {/* <MenuItem style={{ fontFamily: PoliceDefaut }}><NavLink to='' onClick={() => configInfos(userToken, dispatch, navigationPage, 'copyright')}>Agence BEC-copyright</NavLink> </MenuItem> */}
                <MenuItem onClick={() => navigationPage('/copyright/1')} style={{ fontFamily: PoliceDefaut }}>Conditions d'utilisation </MenuItem>
                {/* <MenuItem style={{ fontFamily: PoliceDefaut }}><NavLink to='' onClick={() => configInfos(userToken, dispatch, navigationPage, 'conditionsLegales')}>Mentions légales</NavLink> </MenuItem> */}
                <MenuItem onClick={() => navigationPage('/conditionsLegales/1')} style={{ fontFamily: PoliceDefaut }}>Mentions légales</MenuItem>
                <MenuItem onClick={() => logout()} style={{ fontFamily: PoliceDefaut }} className='border-top'>Déconnexion</MenuItem>
            </Menu>
            <Row className=''>
                <Navbar className='navbar-home'>
                    <Container fluid>
                        <Navbar.Brand className='logoHome d-flex justify-content-start' style={{ marginLeft: '0px' }}>
                            <Row className='' >
                                <Col sm='auto' className='mt-2 mb-2 d-flex justify-content-start' style={{ width: 'fit-content' }}>
                                    <NavLink to={props.retour === 'home' ? '/' : props.retour === 'dashboard' && eventSelected !== undefined ? '/dashboard/' + eventSelected : '/'} className='d-flex justify-content-end'>
                                        <img src={LOGO} alt="" style={{ width: 'auto', height: '90px', paddingTop: '2%' }} />
                                    </NavLink>
                                </Col>
                                {props.evenement ?
                                    <Col className='p-0'>
                                        <img className='evenement-navbar-icone' src={props.evenement} alt="evenement" style={{}} />
                                    </Col>
                                    :
                                    <></>
                                }

                            </Row>
                        </Navbar.Brand>
                        {props.menus ?
                            <Navbar.Collapse className='collapse' id="basic-navbar-nav" style={{ display: 'none' }}>
                                <Nav className='me-auto collapse-menu d-flex justify-content-end align-items-center'>
                                    <Nav.Item className=''>

                                        <div className='d-flex' style={{ cursor: 'pointer' }} onClick={() => navigationPage('/messagerie')}>
                                            <div className='' sm='auto' style={{ width: 'fit-content' }}>
                                                <img className='icone' style={{}} src={pictos.messagerie_icone} alt="" />
                                            </div>
                                            <div sm='auto' className='texteMenus d-flex align-items-center px-1'>
                                                {'Messages'}
                                            </div>

                                        </div>


                                    </Nav.Item>

                                    <Nav.Item className=''>
                                        <div className='d-flex' style={{ cursor: 'pointer' }} onClick={() => navigationPage('/contacts')}>
                                            <div className='' sm='auto' style={{ width: 'fit-content' }}>
                                                <img className='icone' style={{}} src={pictos.contacts_icone} alt="" width={'30px'} />
                                            </div>
                                            <div sm='auto' className='texteMenus d-flex align-items-center px-1'>
                                                {'Mes contacts'}
                                            </div>

                                        </div>

                                    </Nav.Item>
                                    <Nav.Item className=''>
                                        <div className='d-flex' style={{ cursor: 'pointer' }} id='btn-compte' aria-controls={open ? 'profil-menu' : undefined} aria-haspopup={true} aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                                            <div className='' sm='auto' style={{ width: 'fit-content' }}>
                                                {props.photo ? <Avatar src={props.photo} style={{ height: '35px', width: '35px' }} className='avatar-navigation mx-1' /> : <img src={pictos.compte_icone} alt="" className='icone' width={'30px'} />}
                                            </div>
                                            <div sm='auto' className='texteMenus d-flex align-items-center px-1'>
                                                {"Mon compte"}
                                            </div>

                                        </div>

                                    </Nav.Item>
                                    <Nav.Item style={{}} className=''>
                                        <Row >
                                            <Col sm='auto'>
                                                <Row className='' >
                                                    <NavLink to={'/notifications'} >
                                                        <Row className=' d-flex ' >
                                                            <StyledBadge badgeContent={nbreNotif.nbre} >
                                                                <img className='notif-icone icone' src={pictos.notification_icone} alt="Notifications" />
                                                            </StyledBadge>
                                                        </Row>
                                                    </NavLink>

                                                </Row>

                                            </Col>
                                        </Row>
                                    </Nav.Item>



                                </Nav>
                            </Navbar.Collapse>
                            :
                            null
                        }

                    </Container>
                </Navbar>
            </Row >

            <Row className='d-flex justify-content-center mb-2 mt-4'>
                <Row style={{ position: 'relative' }} className={props.main ? 'participant-picto mt-0 ' : 'participant-picto'}>
                    <img src={props.icone} alt="" style={{ position: 'absolute', zIndex: '1', width: '120px', height: 'auto', margin: 'auto', }} />
                </Row>
                {props.content}
                <Row className='participantTitle'>
                    <div className=''>{props.titre}</div>
                </Row>
            </Row>

        </Container >
    );
};

export default Navigation;