import React from 'react';
import '../styles/login.css'
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { pagesContent } from '../redux/profilService';
import { useState } from 'react';
import ErrorPopup from '../components/ErrorPopup';
import LOGO from '../assets/img/logo.png'
import { useNavigate } from 'react-router';
import { LinearProgress } from '@material-ui/core';
import APPSTORE from '../assets/img/Bouton__App Store.png'
import GOOGLEPLAY from '../assets/img/Bouton__Google Play.png'
import textConnect from '../textConnect.json'
import axios from 'axios';

const Principale = () => {
    const dispatch = useDispatch()
    const texteIntro = useSelector(state => state.profil.contenusTexte !== undefined ? state.profil.contenusTexte.message_introduction : "")
    const contenusBO = useSelector(state => state.profil.contenusTexte)
    const [openError, setOpenError] = useState(false)
    const [msgError, setMsgError] = useState('')
    const closeErrorHandle = () => {
        setOpenError(false)
    }

    const [loader, setLoader] = useState(true)
    const [events, setEvents] = useState([]);

    const [netStatus, setNetStatus] = useState(() => {
        if (navigator.onLine) {
            console.log("Connected to network.");
            return true;
        } else {
            return false;
        }
    });

    const fetchEvents = async() => {
      try {
        const {data} = await axios.get('https://manage.neoevent.app/api/evenements/actifs');
        console.log('events', data);
        setEvents(data);
      } catch (error) {
        console.log("error occured on fetching events", error);
      }
    }

    useEffect(() => {
        window.ononline = (e) => {
            console.log("Connected to network.");
            setNetStatus(true);
        };
        window.onoffline = (e) => {
            console.log("Network connection lost.");
            setNetStatus(false);
        };

    }, [netStatus])

    useEffect(() => {
        pagesContent().then((reponse) => {
            dispatch({
                type: 'Profil/addTextContent',
                payload: reponse.data

            })
            setLoader(false)
        }).catch((erreur) => {
            // setLoader(false)
            if (!netStatus) {
                setMsgError(textConnect.internet)
                setOpenError(true)
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }

            if (erreur.response) {
                if (erreur.response.status !== 401) {
                    setMsgError(textConnect.error)
                    setOpenError(true)
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                }
            }

        })
       
    }, [])

    useEffect(() => {
      (async() => await fetchEvents())();
    }, []);

    const navigate = useNavigate()
    return (

        <>
            {loader ?
                <>
                    <LinearProgress />
                    <ErrorPopup
                        message={msgError}
                        open={openError}
                        close={() => closeErrorHandle()}
                    />
                </>

                :
                <Container fluid className='d-flex align-items-center login min-vh-100' style={{ position: '' }}>
                    <video id='login-video' className='video-login ' src={contenusBO !== undefined ? contenusBO.fichier : ''} preload='auto' muted type='video/mp4' autoPlay={true} loop={true} playsInline poster=''>
                        impossible de lire la vidéo
                    </video>

                    <Row className='d-flex align-items-center' style={{ margin: 'auto', minHeight: '100vh', minWidth: '90vw' }} >


                        <div className='' style={{ zIndex: '600', minHeight: '', width: '100%' }}>
                            <Container>
                                <Row className='logo-login ' style={{ margin: 'auto' }}>
                                    < img src={LOGO} alt="" className='' style={{ zIndex: '600' }} />
                                </Row>
                                <Row className='text-login text-center m-auto my-2' style={{ border: 'none' }}>
                                    {texteIntro === undefined ?
                                        <></>
                                        :
                                        <p
                                            className='p-0'
                                            dangerouslySetInnerHTML={{
                                                __html: texteIntro
                                            }}
                                        />
                                    }
                                </Row>


                                <Row className=' mt-5'>
                                    <Col className='texte-choix-evt' style={{ textAlign: 'center', fontSize: '1.5rem' }}>
                                        Téléchargez l'application
                                    </Col>
                                </Row>
                                <Row className='my-4' style={{ margin: 'auto', width: 'fit-content' }}>
                                    <Col onClick={() => window.open("https://apps.apple.com/us/app/bec-neo/id6450933172", '_blank')} className='d-flex justify-content-center p-0' style={{ width: 'fit-content' }}>
                                        <img className='' style={{ width: '270px', cursor: 'pointer' }} src={APPSTORE} alt="" />
                                    </Col>

                                    <Col onClick={() => window.open("https://play.google.com/store/apps/details?id=com.neo_bec", '_blank')} className='d-flex justify-content-center p-0' style={{ width: 'fit-content' }}>
                                        <img style={{ width: '270px', cursor: 'pointer' }} src={GOOGLEPLAY} alt="" />
                                    </Col>

                                </Row>
                                <Row className='my-2'>
                                    <Col className='d-flex justify-content-center'>
                                        <Button className='py-2' onClick={() => navigate('/login')} style={{ borderRadius: '50px', backgroundColor: '' }}>
                                            Se connecter à mon compte
                                        </Button>
                                    </Col>

                                </Row>
                                <Row>
                                 <div className='d-flex justify-content-center align-items-center'>
                                   <p className='access-wording'>
                                     L'accès à la plateforme nécessite d'être inscrit. Pour s'inscrire, 
                                     cliquez sur la manifestation de votre choix
                                    </p>
                                 </div>
                                 <div className='logo-container'>
                                    {events.map(({url, photo}, index) => {
                                        return (
                                          <>
                                            <a href={url} target='_blanck' style={{margin: '20px'}} key={index}>
                                              <img style={{ width: '200px', cursor: 'pointer' }} src={photo} alt="" />
                                            </a>
                                          </>
                                        )
                                    })}
                                  </div>
                                </Row>
                            </Container>
                        </div>
                        {/* <footer className='d-flex align-items-end footer-login' style={{ marginTop: 'auto' }}>
                    bb
                </footer> */}
                    </Row>
                </Container>
            }

        </>

    );
};

export default Principale;