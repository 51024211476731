import accueil_icone from './PICTO__ACCUEIL.png'
import ajout_contact_icone from './PICTO__AJOUT CONTACT.png'
import badge_icone from './PICTO__BADGES.png'
import congressiste_icone from './PICTO__CONGRESSISTE.png'
import entreprise_icone from './PICTO__ENTREPRISE.png'
import expert_icone from './PICTO__EXPERT.png'
import fermer_icone from './PICTO__FERMER.png'
import filtrer_icone from './PICTO__FILTRER.png'
import intervenant_icone from './PICTO__INTERVENANT.png'
import journee_icone from './PICTO__JOURNEE.png'
import journee_rond_icone from './PICTO__JOURNEE_ROND.png'
import linkedin_icone from './PICTO__LINKEDIN.png'
import mail_icone from './PICTO__MAIL.png'
import contacts_icone from './PICTO__MES CONTACTS.png'
import contact_rond_icone from './PICTO__CONTACT_ROND_WEB copie.png'
import messagerie_icone from './PICTO__MESSAGERIE.png'
import messagerie_rond_icone from './PICTO__MESSAGE_ROND_WEB.png'
import modifier_icone from './PICTO__MODIFIER.png'
import compte_icone from './PICTO__MON COMPTE.png'
import notification_icone from './PICTO__NOTIFICATIONS.png'
import notification_rond_icone from './PICTO__NOTIFICATION_ROND_WEB.png'
import partenaires_icone from './PICTO__PARTENAIRES.png'
import partenaires_rond_icone from './PICTO__PARTENAIRES_ROND.png'
import participant_icone from './PICTO__PARTICIPANTS.png'
import participant_rond_icone from './PICTO__PARTICIPANTS_ROND.png'
import plan_stand_icone from './PICTO__PLAN STAND.png'
import plan_stand_rond_icone from './PICTO__PLAN STAND_ROND.png'
import programme_icone from './PICTO__PROGRAMME.png'
import programme_rond_icone from './PICTO__PROGRAMME_ROND.png'
import rechercher_icone from './PICTO__RECHERCHER.png'
import speaker_icone from './PICTO__SPEAKER.png'
import tel_icone from './PICTO__TEL.png'
import twitter_icone from './PICTO__TWITTER.png'
import visiteur_icone from './PICTO__VISITEUR.png'
import voir_icone from './PICTO__VOIR.png'
import demande_rdv_icone from './PICTO__DEMANDE RDV.png'
import evenement_min_icone from './PICTO__EVENEMENT_MIN.png'
import atelier_min_icone from './PICTO__ATELIER_MIN.png'
import conference_min_icone from './PICTO__CONFERENCE-MIN.png'
import formation_min_icone from './PICTO__FORMATION_MIN.png'
import lieu_icone from './PICTO__LIEU.png'
import plateau_tv_icone from './PICTO__PLATEAU TV.png'
import plateau_tv_min_icone from './PICTO__PLATEAT TV_MIN.png'
import rendez_vous_icone from './PICTO__RENDEZ-VOUS.png'
import rendez_vous_min_icone from './PICTO__RENDEZ-VOUS_MIN.png'
import rendez_vous_creation_icone from './PICTO__CALENDRIER_PLUS.png'
import table_ronde_min_icone from './PICTO__TABLE RONDE_MIN.png'
import table_ronde_icone from './PICTO__TABLE RONDE.png'
import demande_attente_icone from './PICTO__EN ATTENTE.png'
import demande_valide_icone from './PICTO__VALIDE.png'
import adresse_icone from './PICTO_Adresse.png'
import poubelle_icone from './PICTO_Poubelle.png'



export const pictos = {
    accueil_icone,
    ajout_contact_icone,
    badge_icone,
    congressiste_icone,
    entreprise_icone,
    expert_icone,
    fermer_icone,
    filtrer_icone,
    intervenant_icone,
    journee_icone,
    linkedin_icone,
    mail_icone,
    contacts_icone,
    messagerie_icone,
    modifier_icone,
    compte_icone,
    notification_icone,
    partenaires_icone,
    participant_icone,
    plan_stand_icone,
    programme_icone,
    rechercher_icone,
    speaker_icone,
    tel_icone,
    twitter_icone,
    visiteur_icone,
    voir_icone,

    journee_rond_icone,
    contact_rond_icone,
    messagerie_rond_icone,
    notification_rond_icone,
    partenaires_rond_icone,
    participant_rond_icone,
    plan_stand_rond_icone,
    programme_rond_icone,

    demande_rdv_icone,
    evenement_min_icone,
    atelier_min_icone,
    conference_min_icone,
    formation_min_icone,
    lieu_icone,
    plateau_tv_icone,
    plateau_tv_min_icone,
    rendez_vous_icone,
    rendez_vous_min_icone,
    table_ronde_min_icone,
    table_ronde_icone,

    rendez_vous_creation_icone,
    demande_attente_icone,
    demande_valide_icone,
    adresse_icone,
    poubelle_icone

};