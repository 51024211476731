import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import Main from './pages/Main';
import User from './pages/fonctions';
import React, { useContext, useEffect, useState, Component, useRef } from 'react';
import { BreadcrumbContext, CircleContext, LoggedContext, NotifContext, NotifNbreContext } from './Context';
import Dashboard from './pages/Dashboard';
import Programme from './pages/Programme';
import Compte from './pages/Compte';
import Participants from './pages/Participants';
import CompteParticipant from './pages/CompteParticipant';
import ProgrammeDetail from './pages/ProgrammeDetail';
import Journee from './pages/Journee';
import Contacts from './pages/Contacts';
import Messages from './pages/Messages';
import Chatbox from './pages/Chatbox';
// import { clientWebsocketNotifications } from './websocket';
import useWebSocket from "react-use-websocket";
import { SOCKETURL } from "./redux/api";
import { useDispatch, useSelector } from 'react-redux';
import { NotificationsRedux } from './redux/profilService';
import Parametres from './pages/Parametres';
import NousContacter from './pages/NousContacter';
import PartenairesExpo from './pages/PartenairesExpo';
import PartenaireDetail from './pages/PartenaireDetail';
import { Worker } from '@react-pdf-viewer/core';
import Copyright from './pages/Copyright';
import ConditionsLegales from './pages/ConditionsLegales';
import NotificationsPage from './pages/NotificationsPage';
import Rendezvous from './pages/Rendezvous';
import { resetUser } from './redux/authSlice';
import PlanStand from './pages/PlanStand';
import { addHistorique } from './redux/profilSlice';
import ClotureCompte from './pages/ClotureCompte';
import SuppressionCompte from './pages/SuppressionCompte';
import Principale from './pages/Principale';
import CompteDeleted from './pages/CompteDeleted';

function App() {
  const souvenir = useSelector(state => state.user.souvenir)
  const [logged, setLogged] = useState(souvenir);
  const [circle, setCircle] = useState(false)
  const [message, setMessage] = useState(null)
  const [nbre, setNbre] = useState(0)
  const [pages, setPages] = useState([{ url: '/', nom: 'Accueil' }])

  const userToken = useSelector(state => state.user.access)
  const monProfil = useSelector(state => state.profil.profil)
  const evenementSelected = useSelector(state => state.evenements.evenementSelect)

  // const nbreNotif = useContext(NotifNbreContext)
  const dispatch = useDispatch()

  const location = useLocation()

  // const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(userToken === '' ? null : `${SOCKETURL}/notification/?token=${userToken}`,
  const openWebsocket = useWebSocket(userToken === '' ? null : `${SOCKETURL}/notification/?token=${userToken}`,
    {
      onOpen: () => {
        console.log('Websocket notifications connecté')

      },
      onMessage: () => {
        NotificationsRedux(userToken).then((reponse) => {
          dispatch(
            {
              type: 'Profil/addNotifications',
              payload: reponse.data.filter(item =>
                item.participant_recepteur.id === monProfil.id && item.etat === false
              )
            }
          );
          setNbre(reponse.data.filter(item =>
            item.participant_recepteur.id === monProfil.id && item.etat === false
          ).length)

        }).catch((erreur) => {
          console.log(erreur)
        })
      },
      onError: () => {
        console.log('Erreur avec le websocket')
        // App()
        openWebsocket.getWebSocket()

      },
      onClose: () => {
        console.log('Websocket fermé')
        // App()
        openWebsocket.getWebSocket()
      }

    }
  )


  var lien = ''
  const rdv = useSelector(state => state.profil.notifications.filter(item => item.data_target !== null))

  const history = useSelector(state => state.profil.historique)

  useEffect(() => {
    if (userToken === '') {
      if (location.pathname !== '/' && (location.pathname === '/contacts' || location.pathname === '/notifications' || location.pathname === '/rendezvous')) {
        dispatch(addHistorique(location.pathname))
        // if (location.pathname === '/rendezvous') {
        //   dispatch(addHistorique('/notifications'))
        // }
      }
      else {
        dispatch(addHistorique('/'))
      }

      // lien = location.pathname

    }
    // console.log(history)
    // console.log(souvenir)
    // console.log(pages)
    // console.log(location.pathname)

    // if (!souvenir) {
    //   dispatch(resetUser())
    // }

    // getWebSocket().onmessage = 
    // getWebSocket().onerror = 
    // getWebSocket().onclose = 





  }, [souvenir, location.pathname])






  return (
    <div>

      <LoggedContext.Provider
        value={{ logged: logged, setLogged: setLogged }}
      >
        <NotifContext.Provider
          value={{ message: message, setMessage: setMessage }}
        >
          <CircleContext.Provider
            value={{ circle: circle, setCircle: setCircle }}
          >
            <NotifNbreContext.Provider
              value={{ nbre: nbre, setNbre: setNbre }}
            >
              <BreadcrumbContext.Provider
                value={{ pages: pages, setPages: setPages }}
              >
                <Routes>
                  <Route exact path='/' element={logged === true && userToken !== '' ? <Navigate to={'/main'} /> : userToken !== '' ? <Navigate to={'/main'} /> : <Principale />}></Route>
                  <Route path='/conditionsLegales/:login' element={<ConditionsLegales />} />
                  <Route path='/copyright/:login' element={<Copyright />} />
                  <Route path='/clotureCompte' element={<ClotureCompte />} />
                  <Route path='/deleted' element={<CompteDeleted />} />
                  {userToken !== '' ?

                    <>
                      <Route path={'/login'} element={logged === true && userToken !== '' ? <Navigate to={'/main'} /> : <Login />} />
                      <Route path={'/main'} element={history === '/' ? <Main /> : <Navigate to={history} />} />
                      <Route path='/dashboard/:idevent' element={<Dashboard></Dashboard>}></Route>
                      <Route path='/programmes' element={<Programme></Programme>}></Route>
                      <Route path='/programme/:idprogram/:type' element={<ProgrammeDetail></ProgrammeDetail>}></Route>
                      <Route path='/compte' element={<Compte></Compte>}></Route>
                      <Route path='/participants' element={<Participants></Participants>}></Route>
                      <Route path='/compteParticipant/:idparticipant/:contact' element={<CompteParticipant></CompteParticipant>}></Route>
                      <Route path='/majournee' element={<Journee />} />
                      <Route path='/contacts' element={<Contacts />} />
                      <Route path='/messagerie' element={<Messages />} />
                      <Route path='/chat/:chatid/:room/:contact' element={<Chatbox />} />
                      <Route path='/parametres' element={<Parametres />} />
                      <Route path='/nousContacter' element={<NousContacter />} />
                      <Route path='/partenaires' element={<PartenairesExpo />} />
                      <Route path='/partenaire/:id/:premium' element={<PartenaireDetail />} />
                      <Route path='/notifications' element={<NotificationsPage />} />
                      <Route path='/rendezvous' element={rdv.length === 0 ? <Navigate to='/' /> : <Rendezvous />} />
                      <Route path='/planStand/:idPartenaire' element={<PlanStand />} />
                      <Route path='/suppressionCompte' element={<SuppressionCompte />} />


                      <Route path='*' element={evenementSelected === '' ? <Navigate to={'/'} /> : <Navigate to={`/dashboard/${evenementSelected.id}`} />}></Route>
                    </>
                    :
                    <>
                      {/* <Route path={'/main'} element={history === '/' ? <Main /> : <Navigate to={history} />} /> */}
                      <Route path={'/login'} element={<Login />} />

                      <Route path='*' element={<Navigate to={'/'} />}></Route>
                    </>

                  }


                  {/* <Route path='*' element={<Navigate to={'/'} />}></Route> */}

                </Routes>
              </BreadcrumbContext.Provider>
            </NotifNbreContext.Provider>
          </CircleContext.Provider>
        </NotifContext.Provider>
      </LoggedContext.Provider>

    </div>
  );
}

export default App;
