import React, { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import "../styles/participants.css";
import { pictos } from "../assets/img/pictos";
import {
  Avatar,
  Badge,
  CircularProgress,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Input from "@mui/joy/Input";
import {
  Check,
  HourglassEmptyTwoTone,
  HourglassFullTwoTone,
  SearchOutlined,
} from "@material-ui/icons";
import { toLower, upperFirst } from "lodash";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  cancelParticipantsRedux,
  participantEvenementSelectRedux,
  participantSearch,
  participantSelectRedux,
  participantsCategories,
  participantsFiltre,
  participantsRedux,
  participantsReduxPage,
} from "../redux/evenementsServices";
import {
  contactsRedux,
  demandesRedux,
  envoyerDemandeRedux,
  invitationsRedux,
  refreshToken,
} from "../redux/profilService";
import Navigation from "../components/Navigation";
import { categories, toNormalForm } from "./fonctions";
import SearchInput from "../components/SearchInput";
import { styled } from "@mui/material";
import { useEffect } from "react";
import ErrorPopup from "../components/ErrorPopup";
import Breadcrumb from "../components/Breadcrumb";
import Chemin from "../components/Chemin";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { URL } from "../redux/api";
import { addParticipants } from "../redux/evenementsSlice";
import textConnect from "../textConnect.json";

const Participants = (props) => {
  // const listeParticipantsInit = useSelector(state => state.evenements.participants)

  const evenementSelected = useSelector(
    (state) => state.evenements.evenementSelect
  );
  const [selectProfil, setSelectProfil] = useState("");

  const monProfil = useSelector((state) => state.profil.profil);
  const accessToken = useSelector((state) => state.user.access);
  const refresh_token = useSelector((state) => state.user.refresh);

  const dispatch = useDispatch();
  const navigationPage = useNavigate();
  const listeParticipantsInit = useSelector((state) =>
    state.evenements.participants.results.filter(
      (item) => item.id !== monProfil.id
    )
  );
  const pagesNav = useSelector(
    (state) => state.evenements.participants.total_pages
  );

  // const [listeParticipantsFiltreInit, setlisteParticipantsFiltreInit] = useState(listeParticipantsInit.filter(item => item.id !== monProfil.id))
  // const [listeParticipantsFiltre, setListeParticipantsFiltre] = useState(listeParticipantsFiltreInit)

  const [openError, setOpenError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const closeErrorHandle = () => {
    setOpenError(false);
  };

  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(false);

  const [filtreMot, setFiltreMot] = useState("");
  //Fonction filtre barre de recherche
  async function filtreParticipants(e) {
    setCatCheck([]);
    setTimeout(() => {
      setFiltreMot(e.target.value);
    }, 1000);

    setPage(1);
  }

  // function FiltrerProfil(categorie = [], tableau = []) {

  //     let resultat = []
  //     if (categorie.length > 1) {

  //         // console.log('superieur a 1..................')
  //         // console.log(categorie)
  //         let tabTemp = []

  //         for (let index = 0; index < categorie.length; index++) {
  //             // console.log('itemeeee', categorie[index])
  //             tableau.filter(item =>
  //                 item.profil.description.includes(categorie[index])).map((item2) =>
  //                     tabTemp.push(item2)
  //                 )

  //             resultat = tabTemp
  //             console.log(resultat)

  //         }

  //     }
  //     else {

  //         resultat = tableau.filter(item =>
  //             item.profil.description.includes(categorie)
  //         )
  //     }

  //     if (expertCheck) {
  //         resultat = tableau.filter(item =>
  //             item.is_expert === expertCheck
  //         )
  //     }
  //     // if (expertCheck === false) {
  //     //     resultat = tableau.filter(item =>
  //     //         item.is_expert === expertCheck
  //     //     )
  //     // }

  //     // console.log('le resultat : ', resultat)
  //     setListeParticipantsFiltre(resultat)
  //     // setAnchorEl(null)
  // }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  //La liste unique des profils filtré par ordre alphabétique
  // const profilsUnique = [...new Map(listeParticipantsFiltreInit.map(item => [item.profil.description, item])).values()].sort(function (a, b) {
  const profilsUnique = [
    ...new Map(
      listeParticipantsInit.map((item) => [item.profil.description, item])
    ).values(),
  ].sort(function (a, b) {
    if (upperFirst(a.profil.description) < upperFirst(b.profil.description)) {
      return -1;
    }
    if (upperFirst(a.profil.description) > upperFirst(b.profil.description)) {
      return 1;
    }
    return 0;
  });

  function annulerFiltre() {
    // setListeParticipantsFiltre(listeParticipantsFiltreInit)
    setExpertCheck(false);
    setCatCheck([]);
    setAnchorEl(null);
    setLoading2(true);
    setPage(1);
    participantsRedux(accessToken, evenementSelected.id).then((reponse) => {
      dispatch({
        type: "evenements/addParticipants",
        payload: reponse.data,
      });
      navigationPage("/participants");
      setLoading2(false);
    });
  }

  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 19,
    height: 19,
    left: -5,
    // border: `2px solid ${theme.palette.background.paper}`,
  }));

  const [catCheck, setCatCheck] = useState([]);
  const [expertCheck, setExpertCheck] = useState(false);
  const expCheck = (e) => {
    if (e.target.checked) {
      setCatCheck([]);
      setExpertCheck(true);
      //   setFiltreMot("");
      setPage(1);
    } else {
      setExpertCheck(false);
      setPage(1);
    }
  };
  const handleCheck = (e, value) => {
    if (e.target.checked) {
      setExpertCheck(false);
      console.log(e.target.checked);
      setCatCheck((old) => [...old, value]);
      setPage(1);
    } else {
      setCatCheck(catCheck.filter((item) => item !== value));
      console.log(catCheck);
      setPage(1);
      //   setFiltreMot("");
    }
  };
  const filterCheck = () => {};
  useEffect(() => {
    dispatch({
      type: "Profil/addChemin",
      payload: breadcrumbs_temp,
    });
    // console.log('dans le hook.......................')
    // console.log(catCheck)
    // FiltrerProfil(catCheck, listeParticipantsFiltreInit)
  }, [catCheck, expertCheck]);
  const [indexPart, setIndexPart] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const controller = new AbortController();
  useEffect(() => {
    setLoading2(true);

    axios.interceptors.response.use(
      function (config) {
        // faire quelque chose avant que la requête ne soit envoyée
        controller.abort();
        // console.log(config)
        return config;
      },
      function (error) {
        // faire quelque chose en cas d’erreur

        return Promise.reject(error);
      }
    );

    participantsFiltre(
      accessToken,
      evenementSelected.id,
      toLower(catCheck.join("-")),
      expertCheck,
      controller
    )
      .then((response) => {
        // console.log(response)
        setLoading2(false);
        dispatch({
          type: "evenements/addParticipants",
          payload: response.data,
        });
      })
      .catch((erreur) => {
        setLoading2(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading2(false);
            });
          }
        }
      });
  }, [catCheck, expertCheck]);

  const [netStatus, setNetStatus] = useState(() => {
    if (navigator.onLine) {
      console.log("Connected to network.");
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      console.log("Connected to network.");
      setNetStatus(true);
    };
    window.onoffline = (e) => {
      console.log("Network connection lost.");
      setNetStatus(false);
    };
  }, [netStatus]);

  useEffect(() => {
    // setLoading2(true);
    axios.interceptors.response.use(
      function (config) {
        // faire quelque chose avant que la requête ne soit envoyée
        controller.abort();
        // console.log(config)
        return config;
      },
      function (error) {
        // faire quelque chose en cas d’erreur
        return Promise.reject(error);
      }
    );

    cancelParticipantsRedux(
      accessToken,
      evenementSelected.id,
      filtreMot,
      controller
    )
      .then(async (response) => {
        // alert(response.data)
        // console.log(response.data)

        setLoading2(false);
        await dispatch({
          type: "evenements/addParticipants",
          payload: response.data,
        });
        // await participantSearch(accessToken, evenementSelected.id, filtreMot).then((response) => {
        //     // console.log(response)
        //     setLoading2(false)
        //     dispatch({

        //         type: 'evenements/addParticipants',
        //         payload: response.data

        //     })
        // })
      })
      .catch((erreur) => {
        setLoading2(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading2(false);
            });
          }
        }
      });
  }, [filtreMot]);

  const [catList, setCatList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    participantsRedux(accessToken, evenementSelected.id)
      .then((reponse) => {
        dispatch({
          type: "evenements/addParticipants",
          payload: reponse.data,
        });
        setTotalPages(reponse.data.total_pages);
        participantsCategories(accessToken, evenementSelected.id)
          .then((response) => {
            // console.log(response.data)
            setCatList(response.data);
            setLoading(false);
          })
          .catch((erreur) => {
            if (!netStatus) {
              setMsgError(textConnect.internet);
              setOpenError(true);
            }

            if (erreur.response) {
              if (erreur.response.status !== 401) {
                setMsgError(textConnect.error);
                setOpenError(true);
              }
              if (erreur.response.status === 401) {
                refreshToken(refresh_token).then(async (reponse) => {
                  dispatch({
                    type: "user/addRefresh",
                    payload: reponse.data.refresh,
                  });
                  dispatch({
                    type: "user/addAccess",
                    payload: reponse.data.access,
                  });
                  console.log("token refresh...");

                  setLoading(false);
                });
              }
            }
          });
      })
      .catch((erreur) => {
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
            });
          }
        }
        setLoading(false);
      });
  }, []);

  const participantSelect = (token, id) => {
    setLoading(true);
    // participantEvenementSelectRedux
    participantSelectRedux(token, id, evenementSelected.id)
      .then((reponse) => {
        dispatch({
          type: "evenements/addParticipantSelect",
          payload: reponse.data,
        });
        navigationPage(`/CompteParticipant/${id}/0`);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((erreur) => {
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }

        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              participantSelect(token, id);
            });
          }
        }
        setLoading(false);
      });
  };
  const location = useLocation();
  //Breadcrumbs
  const breadCrumbs = useSelector((state) => state.profil.chemin);
  const breadcrumbs_temp = breadCrumbs.filter((item) => item.nom === "Accueil");
  breadcrumbs_temp.push({ url: location.pathname, nom: "Participants" });

  const [openEnvoyerDemande, setOpenEnvoyerDemande] = useState(false);

  const [circle, setCircle] = useState(false);
  const ouvrirEnvoyer = async (id, position) => {
    // console.log(id)
    setSelectProfil(id);
    setPositionUser([position, null]);
    console.log(positionUser);

    setOpenEnvoyerDemande(true);
  };
  const [positionUser, setPositionUser] = useState([]);
  const handleEnvoiDemande = () => {
    setCircle(true);
    envoyerDemandeRedux(accessToken, selectProfil, evenementSelected.id)
      .then((reponse) => {
        setCircle(false);
        setPositionUser(positionUser.filter((val) => val !== null));
        setOpenEnvoyerDemande(false);
        console.log("demande envoyé :", reponse.data);
        // participantsRedux(accessToken, evenementSelected.id).then((reponse) => {
        //     dispatch(
        //         {
        //             type: 'evenements/addParticipants',
        //             payload: reponse.data
        //         }
        //     );

        // }).catch((erreur) => {
        //     if (erreur.request) {
        //         if (erreur.request.status === 0) {
        //             console.log(erreur.request)
        //             setMsgError('Vérifiez votre connexion internet')
        //             setOpenError(true)
        //         }
        //     }

        //     if (erreur.response) {
        //         if (erreur.response.status !== 401) {
        //             setMsgError("Une erreur s'est produite")
        //             setOpenError(true)
        //         }

        //     }

        // })

        // participantsRedux(accessToken, dispatch, evenementSelected.id)
      })
      .catch((erreur) => {
        setCircle(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");

              setLoading(false);
              handleEnvoiDemande();
            });
          }
        }
      });
  };

  // useEffect(() => {
  //     axios.get(`${URL}/v1/api/evenements/${evenementSelected.id}/participants`).then((response) => {
  //         console.log(response)
  //     })
  // }, [page])

  const pageChange = async (value) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setLoading2(true);

    participantsReduxPage(accessToken, value)
      .then((response) => {
        setPositionUser([]);
        dispatch({
          type: "evenements/addParticipants",
          payload: response.data,
        });
        setLoading2(false);
        // dispatch()
      })
      .catch((erreur) => {
        setLoading2(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");
              pageChange(value);
            });
          }
        }
      });
  };
  const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    // alert(value);

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setLoading2(true);

    participantsReduxPage(
      accessToken,
      evenementSelected.id,
      value,
      toLower(catCheck.join("-")),
      expertCheck,
      controller,
      filtreMot
    )
      .then((response) => {
        setPage(value);
        setPositionUser([]);
        dispatch({
          type: "evenements/addParticipants",
          payload: response.data,
        });
        setLoading2(false);
        // dispatch()
      })
      .catch((erreur) => {
        setLoading2(false);
        if (!netStatus) {
          setMsgError(textConnect.internet);
          setOpenError(true);
        }
        if (erreur.response) {
          if (erreur.response.status !== 401) {
            setMsgError(textConnect.error);
            setOpenError(true);
          }
          if (erreur.response.status === 401) {
            refreshToken(refresh_token).then(async (reponse) => {
              dispatch({
                type: "user/addRefresh",
                payload: reponse.data.refresh,
              });
              dispatch({
                type: "user/addAccess",
                payload: reponse.data.access,
              });
              console.log("token refresh...");
              pageChange(value);
            });
          }
        }
      });
  };

  return (
    <Container fluid className="" style={{ width: "100%" }}>
      <Modal
        className="d-flex align-items-center"
        show={openEnvoyerDemande}
        onHide={() => setOpenEnvoyerDemande(false)}
        style={{ fontFamily: "Poppins" }}
      >
        <Modal.Header
          closeVariant="white"
          closeButton
          style={{ color: "white", backgroundColor: "#271d67" }}
        >
          <h4>Confirmation</h4>
        </Modal.Header>
        <form>
          <Modal.Body>
            Voulez-vous vraiment envoyer une demande ?
            <div className="d-flex justify-content-end mt-3">
              {circle ? (
                <CircularProgress />
              ) : (
                <Button
                  className="btn-recup"
                  onClick={() => handleEnvoiDemande()}
                >
                  Confirmer
                </Button>
              )}

              <Button
                className="btn-recup2 mx-2"
                variant="secondary"
                onClick={() => setOpenEnvoyerDemande(false)}
              >
                Fermer
              </Button>
            </div>
          </Modal.Body>
        </form>
      </Modal>

      <ErrorPopup
        message={msgError}
        open={openError}
        close={() => closeErrorHandle()}
      />

      <Row>
        <Navigation
          button={true}
          titre={"Participants"}
          icone={pictos.participant_rond_icone}
          evenement={evenementSelected.photo}
          photo={monProfil.photo_path}
          retour="dashboard"
          selected={4}
          open={(!open).toString()}
          menus={true}
        />
      </Row>
      {loading2 ? (
        <Row className="" style={{ marginTop: "-7px" }}>
          <LinearProgress />
        </Row>
      ) : null}

      <Row className="p-0">
        <Chemin breadCrumbs={breadcrumbs_temp} />
      </Row>
      <Menu
        className="menu-filtre"
        id="filtre-menu"
        aria-labelledby="btn-filtre"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography
          className="mx-3 border-bottom"
          style={{ fontFamily: "Poppins-bold" }}
        >
          {" Filtrer par catégorie :"}
        </Typography>

        <MenuItem className="" style={{ fontFamily: "Poppins" }}>
          <Col>
            <label htmlFor={"expert"}>Expert</label>
          </Col>
          <Col className="" sm="auto">
            <input
              id={"expert"}
              checked={expertCheck === true ? true : false}
              type="checkbox"
              name=""
              onChange={(e) => expCheck(e)}
            />
          </Col>
        </MenuItem>

        {catList.map((item, index) => (
          // <MenuItem key={index} className='' style={{ fontFamily: 'Poppins' }} onClick={() => FiltrerProfil(item.profil.description, listeParticipantsFiltreInit)}> {upperFirst(item.profil.description)}</MenuItem>
          <MenuItem key={index} className="" style={{ fontFamily: "Poppins" }}>
            <Col>
              <label htmlFor={index}>{upperFirst(toLower(item.nom))}</label>
            </Col>
            <Col className="" sm="auto">
              <input
                id={index}
                checked={
                  catCheck.filter((cat) => cat === item.nom).length
                    ? true
                    : false
                }
                type="checkbox"
                name=""
                onChange={(e) => handleCheck(e, item.nom)}
              />
            </Col>
          </MenuItem>
        ))}
        <MenuItem
          className="border-top"
          style={{ fontFamily: "Poppins" }}
          onClick={() => annulerFiltre()}
        >
          Annuler le filtre
        </MenuItem>
      </Menu>
      {loading ? (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <Row className="container-participants d-flex justify-content-center">
          <Row className="mb-4 recherche-container-participant d-flex align-items-center justify-content-center">
            <SearchInput
              placeholder={"Rechercher un participant"}
              filtre={filtreParticipants}
              //   value={filtreMot}
            />

            <Col
              sm="auto"
              className=""
              style={{ width: "fit-content", cursor: "pointer" }}
              aria-controls={open ? "filtre-menu" : undefined}
              aria-haspopup={true}
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <Row
                className="d-flex justify-content-center"
                style={{ textAlign: "center", height: "20px", margin: "" }}
              >
                {" "}
                {"Filtrer"}{" "}
              </Row>
              <img
                className="row"
                src={pictos.filtrer_icone}
                alt=""
                style={{ width: "30px", margin: "auto" }}
              />
            </Col>
          </Row>

          <Row className="">
            {/* {listeParticipantsInit.filter((item) => {
                            return filtreMot.toLowerCase() === '' ? item : (toNormalForm(item.nom.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.prenom.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.fonction.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())) || toNormalForm(item.organisation.nom.toLowerCase()).includes(toNormalForm(filtreMot.toLowerCase())))
                        }).map((item, index) => */}
            {listeParticipantsInit.map((item, index) => (
              <Row
                key={index}
                className="participant-item d-flex align-items-center"
                style={{ textDecoration: "none" }}
              >
                <NavLink
                  className="d-flex col align-items-center justify-content-start"
                  style={{ textDecoration: "none", width: "fit-content" }}
                  onClick={() => {
                    participantSelect(accessToken, item.id);
                  }}
                >
                  <Col
                    sm="auto"
                    className="photo-profil-participant"
                    style={{ paddingRight: "15px" }}
                  >
                    <Badge
                      className="d-flex align-items-center justify-content-start"
                      style={{ width: "", left: "-8px" }}
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      badgeContent={categories(
                        item.profil.photo,
                        item.profil.description,
                        item.is_expert,
                        item.is_speaker
                      ).map((item2, index) => (
                        <SmallAvatar
                          title={item2.title}
                          key={index}
                          className="col p-0"
                          src={item2.icone}
                          alt=""
                          style={{
                            width: "20px",
                            height: "20px",
                            marginLeft: "",
                          }}
                        />
                      ))}
                    >
                      <Avatar
                        id="photoProfil"
                        className="avatar-profil"
                        src={item.photo_path}
                        style={{ width: "", height: "", backgroundColor: "" }}
                      />
                    </Badge>
                  </Col>
                  <Col className="">
                    <Row className="noms-participant">
                      <Col>
                        {upperFirst(item.prenom.toLowerCase()) +
                          " " +
                          upperFirst(item.nom.toLowerCase())}
                      </Col>{" "}
                    </Row>
                    <Row>
                      <Col>{upperFirst(item.fonction.toLowerCase())}</Col>
                    </Row>
                    <Row>
                      <Col>
                        {item.raison_sociale === undefined ||
                        item.raison_sociale === "" ||
                        item.raison_sociale === null
                          ? !item.from_structure
                            ? upperFirst(item.organisation.nom.toLowerCase())
                            : upperFirst(
                                item.organisation.adresse_organisation.ville.toLowerCase()
                              )
                          : upperFirst(item.raison_sociale.toLowerCase())}
                      </Col>
                    </Row>
                  </Col>
                </NavLink>
                <Col
                  sm="auto"
                  className="d-flex justify-content-end "
                  style={{ width: "fit-content" }}
                >
                  {item.is_contact_status === "pending" ||
                  (positionUser.length === 1 && positionUser[0] === index) ? (
                    <img
                      src={pictos.demande_attente_icone}
                      alt=""
                      style={{ width: "30px" }}
                    />
                  ) : item.is_contact_status === "accepted" ? (
                    <img
                      src={pictos.demande_valide_icone}
                      alt=""
                      style={{ width: "30px" }}
                    />
                  ) : item.is_contact_status === "invite" ? (
                    <NavLink onClick={() => ouvrirEnvoyer(item.id, index)}>
                      <img
                        title="Ajouter"
                        src={pictos.ajout_contact_icone}
                        alt=""
                        style={{ width: "30px" }}
                      />
                    </NavLink>
                  ) : null}
                </Col>
              </Row>
            ))}
          </Row>
          {listeParticipantsInit.length === 0 ? (
            <p
              className=""
              style={{
                textAlign: "center",
                color: "#271d67",
                fontSize: "120%",
              }}
            >
              Aucun résultat trouvé
            </p>
          ) : (
            <Row
              className="mb-4 d-flex justify-content-center p-0"
              style={{ width: "" }}
            >
              <Stack>
                <Pagination
                  style={{ margin: "auto", fontFamily: "Poppins" }}
                  size="large"
                  variant="outlined"
                  color="primary"
                  count={pagesNav}
                  page={page}
                  onChange={handleChangePage}
                  disabled={loading2 === true ? true : false}
                ></Pagination>
              </Stack>

              {/* <Col className=" d-flex justify-content-end">
                
                <Button
                  className="buttons-pagination"
                  disabled={
                    pagesNav.previous === null || loading2 === true
                      ? true
                      : false
                  }
                  onClick={() => pageChange(pagesNav.previous)}
                >
                  {"<"}
                </Button>
              </Col> */}
              {/* <Col sm='auto d-flex align-items-center'>2/5</Col> */}
              {/* <Col className=" d-flex justify-content-start">
                <Button
                  className="buttons-pagination"
                  disabled={
                    pagesNav.next === null || loading2 === true ? true : false
                  }
                  onClick={() => pageChange(pagesNav.next)}
                >
                  {">"}
                </Button>
              </Col> */}
            </Row>
          )}

          {/* <Row className='mb-4 d-flex justify-content-center p-0' style={{ width: 'fit-content' }}>
                        <Stack spacing={5}>
                            <Pagination style={{ margin: 'auto' }} className='' count={totalPages} page={page} onChange={handleChange} />
                        </Stack>
                    </Row> */}
        </Row>
      )}
    </Container>
  );
};

export default Participants;
