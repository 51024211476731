import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navigation from '../components/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import "../styles/cloture.css";
import Chemin from '../components/Chemin';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const ClotureCompte = () => {
    // const monProfil = useSelector(state => state.profil.profil)
    const location = useLocation()

    const breadCrumbs = useSelector(state => state.profil.chemin)
    const breadcrumbs_temp = breadCrumbs.filter((item => item.nom === 'Accueil'))
    breadcrumbs_temp.push({ url: location.pathname, nom: 'Clôturer son compte' })
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: 'Profil/addChemin',
            payload: breadcrumbs_temp
        })
    }, [])

    return (
        <Container fluid>
            <Row>
                <Navigation
                    button={false}
                    titre={"Clôturer son compte"}
                    // photo={monProfil.photo_path}
                    retour='dashboard'
                    menus={false}
                />
            </Row>
            <Row className='p-0'>
                <Chemin
                    breadCrumbs={breadcrumbs_temp}
                />
            </Row>
            <Row className='contact-container cloture-container' style={{ borderBottom: 'none' }}>
                <Col>
                    <p>
                        Pour clôturer votre compte NEO,<br />
                        connectez-vous à l'application, puis allez dans la rubrique "Mon compte" {'>'} Paramètres puis cliquez sur le lien et un email vous sera envoyé pour clôturer le compte, puis vous recevrez un email de confirmation.
                    </p>
                </Col>

            </Row>
        </Container>
    );
};

export default ClotureCompte;